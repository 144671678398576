export const usecases = {
  en: {
    "uc-title": "See How We Helped ",
    "uc-who": "About Them",
    "uc-why": "How they Use Seo-Metrics.net",
    "uc-what": "The return on investment",
    "uc-gs": "Get Started With Seo-Metrics.net",

    "uc-name-1": "Lou-Boutique",
    "uc-body-1":
      "Find out how Lou-Boutique managed to prevent a disaster on the day of their launch.<span>5 hours</span> after launching the marketing campaign their Wordpress fell down. Event though they were busy with suppliers,<span> Seo-Metrics alerted them by email</span>. They were able to ensure that within minutes the problem was fixed. Users did not get a disappointing first impression!",
    "uc-who-1":
      "Lou-Boutique is the online front of the store of the same name. Thanks to their WordPress platform, they can offer and sell hundreds of articles to their users. The site is currently hosted on Amazon Web Services.",
    "uc-why-1":
      "Lou-Boutique mainly uses the monitoring capabilities of Seo-Metrics.Net. Our SaaS mainly ensures that WordPress is always available to users. So, whether it is for because of a hack, a server problem or a programming problem, they do not fear being surprised by a service interruption that would cost the owners a lot of money and reputation. ",
    "uc-what-1":
      "The return on investment of Seo-Metrics.Net was immediate. Indeed, the day the site went into production, once in real conditions, the WordPress encountered a server 500 error. Although this error is easy to solve, the owners were at that time in the rush of the launch day. And without Seo-Metrics.net they wouldn't have realized that all their marketing and all their ads were pointing to a page that wasn't working anymore.<br>Once the problem was known, Seo-Metrics.Net helped them, thanks to the page download (which Seo-Metrics.Net does by default once the specified keyword is not found in the HTML of the page), the teams were able to directly realize the problem and in one Google search they found the solution.",

    "uc-name-2": "La Table Du Moulin",
    "uc-body-2":
      "How La Table du Moulin <span> has passed (and stayed) 1st in Google searches </span>. La Table du Moulin is a restaurant and because of COVID they had to diversify and to compensate for the loss of customers. <span>Thanks to the SEO features of Seo-Metrics.Net</span>, they were able to make sure they were in the most competitive and always improve their online presence.",
    "uc-who-2":
      "La Table du Moulin has a WordPress to present its different menus and its opening hours. Their website is build with popular CMS and use a custom-made DIVI theme. The site is hosted at OVH and is accessible through a CPanel.",
    "uc-why-2":
      "La Table du Moulin wanted to make sure that their good SEO ranking could bring new clients to palliate the loss due to COVID-19. The importance to remain in the top of the research even despite the health crisis is what brought them to Seo-Metrics.Net. With our SaaS they were able to track their competition’s and their own SEO Performance, Site Speed, etc.",
    "uc-what-2":
      "Thanks to Seo-Metrics.Net they were able to analyze their SEO. They can at any time receive the LightHouse report that shows them the performance and loading speed of the site. This report can be done from several regions (*pro version). The owners of the Table du Moulin can thus see how their different users perceive the page and how fast they receive it.  The owners have reported a gain of 3 places in the top 10 searches for the keywords that corresponded to them.  ",

    "uc-name-3": "Your Business",
    "uc-body-3":
      "How can we help your Business? If you want to <span>keep an eye on your SEO</span> and want to <span>make sure your site is always accessible to your users</span>, then Seo-Metrics.Net is perfect for you!<br><br> Our SaaS set everything up so that you can be confident in your online business.",
    "uc-who-3":
      "From small businesses that want to be sure that their site is always operational to agencies offering peace of mind to their customers by promising them a monitoring of their website, our customers are varied. But all of them want to be sure that what they have built is always accessible. ",
    "uc-why-3":
      "The IT landscape is changing faster and faster and your end customers always expect the best. A service failure can have disastrous consequences for your company’s reputation. And with the rapid changes in SEO rules, you can never be sure that the effort you put in 3 months ago still grant you the top ranks in Google's results.",
    "uc-what-3":
      "Seo-Metrics.Net’s solution is a tool that allows you to keep an eye on all that information’s. Our SaaS offers you the possibility to monitor SSL (i.e. the validity of your HTTPS certificate), the presence of a specific word in your HTML (which is useful if you want to make sure that the page has loaded correctly) and all SEO measures. Namely the loading speed, the accessibility of your site (is your site easy to read for users) or the best practices to put in place to make your site as accessible as possible. Everything is accessible through an extremely user-friendly dashboard. So, what are you waiting for?",

    "uc-cta": "Read The Use Case",
  },
  fr: {
    "uc-title": "Seo-Metrics.Net aide ",
    "uc-who": "À propos d'eux",
    "uc-why": "Commment ils utilisent Seo-Metrics.Net",
    "uc-what": "Le retour sur investissement",
    "uc-gs": "Commencez avec Seo-Metrics.Net",

    "uc-body-1":
      "Regardez comment Lou-Boutique a réussi à prévenir un désastre le jour de leur lancement. <span>5 heures</span> après avoir lancé la campagne de marketing leur Wordpress est tombé. Bien qu’ils étaient occupés avec des fournisseurs, <span>Seo-Metrics.Net a prévenu par mail</span>. Ils ont pu s’assurer qu’en quelques minutes le problème était réglé. Les utilisateurs n’ont pas eu de première impression décevante !",
    "uc-who-1":
      "Lou-Boutique est la façade en ligne du magasin du même nom. Grâce à la plateforme WordPress, ils peuvent présenter des centaines d’articles à leurs utilisateurs. Le site est hébergé sur Amazon Web Services.",
    "uc-why-1":
      "Lou-Boutique se sert principalement des capacités de monitoring de Seo-Metrics.Net. Notre SaaS s’assure principalement que, à tout moment, le WordPress est disponible pour les utilisateurs. Ainsi que ce soit pour un hack, pour un problème de serveur ou un problème de programmation, ils ne craignent pas d’être surpris par une coupure de service qui coûterait cher en réputation aux propriétaires.",
    "uc-what-1":
      "Le retour sur investissement de Seo-Metrics.Net a été immédiat. En effet, le jour de la mise en production du site, une fois en conditions réelles la boutique a rencontré une erreur serveur 500. <br>Bien que cette erreur soit facile à résoudre, les propriétaires étaient à ce moment dans le rush du lancement. Et sans Seo-Metrics.Net ne se seraient pas rendu compte que tout le marketing et toutes leurs publicités dirigeaient vers une page qui ne fonctionnait plus.<br>En plus d’avoir réussi le jour du lancement a sauvé le budget marketing en informant les équipes d’un problème serveur, grâce au téléchargement de la page (que Seo-Metrics.Nett par défaut une fois que le mot clé spécifié n’est pas trouvé dans le HTML de la page), les équipes ont pu directement se rendre compte du problème et en une recherche Google ont trouvé la solution.",

    "uc-name-2": "La Table Du Moulin",
    "uc-body-2":
      "Comment la Table du Moulin <span>est passée (et restée) 1re des recherches Google</span>. La Table du Moulin est un restaurant et à cause du COVID ils ont dû se diversifier et afin de compenser la perte de clientèle. <span>Grâce aux fonctionnalités SEO de Seo-Metrics.Net</span>, ils ont pu s’assurer d’être dans les plus compétitifs et de toujours améliorer leur présence en ligne.",
    "uc-who-2":
      "La Table du Moulin présente ses différents menus et ses heures d’ouverture alors client grâce à un site WordPress (fait sous DIVI). Le site est hébergé chez OVH, et est accessible par un CPanel.",
    "uc-why-2":
      "La table du moulin voulait faire le maximum pour s’assurer que le ranking SO n’était pas trop impacté par l’échange manque au vide et qu’il pourrait rester dans le top des recherches afin de continuer à faire grandir leur clientèle même malgré la crise sanitaire.",
    "uc-what-2":
      "Grâce à Seo-Metrics.Net ont pu analyser leur SEO ils peuvent à tout moment recevoir le rapport LightHouse qui leur indique la performance, mais aussi la vitesse du site. Ce rapport peut être effectué de plusieurs régions (*version pro). Les propriétaires de la table du moulin peuvent ainsi voir comment leurs différents utilisateurs reçoivent la page et à quelle vitesse.<br> Les propriétaires nous ont remonté un gain de 3 places dans le top 10 des recherches pour les mots-clés qui leur correspondaient.",

    "uc-name-3": "Votre Business",
    "uc-body-3":
    "Comment pouvons-nous vous aider ? Si vous voulez <span>garder un oeil sur votre SEO</span> et que vous voulez être sûr que <span>votre site est toujours accessible à vos utilisateurs </span>, alors Seo-Metrics.Net est parfait pour vous! <br><br>Nous mettons en place tout pour que vous puissiez être confiant dans votre business en ligne.",
    "uc-who-3":
      "Que ce soit des petites entreprises qui veulent être surs que leur site est toujours opérationnel ou des agences offrant la sérénité à leurs clients en leur promettant une surveillance de leur site web, nos clients sont nombreux. Mais tous veulent être certains que ce qu'ils ont construit est toujours accessible.",
    "uc-why-3":
      "Le paysage IT évolue de plus en plus vite et vos clients finaux s'attendent toujours au maximum. Une panne de service peut avoir des conséquences désastreuses pour la réputation de votre entreprise. Et avec les changements rapides dans les règles de SEO, vous ne pouvez jamais être sûr que l'effort que vous avez fourni il y a 3 mois vous propulse toujours à la tête des résultats Google. ",
    "uc-what-3":
      "Pour répondre à ça Seo-Metrics.Net met en place une suite d'outils qui vous permettent de garder toutes ces informations à l’œil. Notre SaaS vous offre la possibilité de monitorer le SSL (c'est-à-dire la validité de votre certificat HTTPS), la présence d'un mot spécifique dans votre HTML (ce qui est utile si vous voulez vous assurer que la page a correctement chargé), mais aussi toutes les mesures SEO. À savoir la vitesse de chargement, l’accessibilité de votre site (est-ce que votre site est facile à lire pour les utilisateurs) ou encore les meilleures pratiques à mettre en place pour que votre site soit le plus facile d’accès. Et toutes ces informations sont accessibles par un dashboard extrêmement user-friendly. Alors qu'est-ce que vous attendez ? ",

    "uc-cta": "Lire La Suite",
  },
};
