import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import i18n from "./../my-i18next.js";
import { withTranslation } from "react-i18next";

//icon
import AlertIconSVG from "./_svg/_alertIconSVG.js";
import SeoIconSVG from "./_svg/_seoFeatIcon.js";
import TsIconSVG from "./_svg/_tsFeatIcon.js";
import HelpIconSVG from "./_svg/_helpFeatIcon.js";

//goo
import AlertFeatSVGGOO from "./_svg/_alertFeatSVGGoo.js";
import SeoFeatSVGGOO from "./_svg/_seoFeatSVGGoo.js";
import TsFeatSVGGOO from "./_svg/_tsFeatSVGGoo.js";
import HelpFeatSVGGOO from "./_svg/_helpFeatSVGGoo.js";

class Header extends Component {
  render() {
    const { t } = this.props;
    return (
      <div id="hightlightfeat">
        <div className="hf-central">
          <div className="hf-title">{t("hf-why-you-should")} </div>
          <div className="hf-feats">
            <div className="hf-feat falert">
              <AlertFeatSVGGOO className="hf-feat-goo" />
              <AlertIconSVG className="hf-feat-icon" />
              <div className="hf-feat-title">{t("hf-alert-title")}</div>
              <div className="hf-feat-body"> {t("hf-alert-desc")} </div>
            </div>
            <div className="hf-feat fseo">
              <SeoFeatSVGGOO className="hf-feat-goo" />
              <SeoIconSVG className="hf-feat-icon" />
              <div className="hf-feat-title">{t("hf-seo-title")}</div>
              <div className="hf-feat-body"> {t("hf-seo-desc")} </div>
              <div className="hf-feat-goo"></div>
            </div>
            <div className="hf-feat fts">
              <TsFeatSVGGOO className="hf-feat-goo" />
              <TsIconSVG className="hf-feat-icon" />
              <div className="hf-feat-title">{t("hf-ts-title")}</div>
              <div className="hf-feat-body"> {t("hf-ts-desc")} </div>
              <div className="hf-feat-goo"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
            //<div className="hf-feat fhelp">
              //<HelpFeatSVGGOO className="hf-feat-goo" />
              //<HelpIconSVG className="hf-feat-icon" />
              //<div className="hf-feat-title">{t("hf-help-title")}</div>
              //<div className="hf-feat-body"> {t("hf-help-desc")} </div>
            //</div>

export default withTranslation()(Header);
