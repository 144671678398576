import * as React from "react"

function SvgComponent(props) {
  return (
        <svg width={263.369} height={305.55} viewBox="0 0 69.683 80.844" {...props}>
          <path
    className="leftShape"
            d="M45.49 64.7C36.84 63.418 22.254 22.639 34.37 7.424 41.43-1.446 61.847-3.015 68.36 6.264c4.462 6.355-2.18 61.508-22.87 58.437z"
            opacity={0.914}
            fill="#feb0b0"
          />
          <path
    className="rightShape"
            d="M10.359 60.252c-7.146-10.366-16.73-35.32-4.48-37.504 11.8-2.105 58.475 40.586 38.077 55.106-13.95 9.93-26.422-7.193-33.597-17.602z"
            opacity={0.789}
            fill="#4089cd"
          />
        </svg>
      
  )
  
}

export default SvgComponent

