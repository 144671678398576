import * as React from "react";

function Price1(props) {
  return (
    <svg
      width={360.785}
      height={336.837}
      viewBox="0 0 95.458 89.121"
      {...props}>
      <path
        d="M.505 52.432c.963-3.92 3.957-9.075 8.197-14.116 4.46-5.302 21.69-23.529 25.869-27.364C41.405 4.68 46.5 1.712 52.618.442c7.169-1.49 16.372.843 24.337 6.167 3.06 2.045 7.708 6.458 9.953 9.45 7.717 10.282 10.497 25.967 7.158 40.398-2.874 12.423-10.158 22.446-19.853 27.316-2.248 1.13-7.47 2.824-10.725 3.481-7.345 1.483-21.666 2.3-28.566 1.631-11.668-1.132-19.241-4.517-25.942-11.597C4.877 72.952 1.92 67.806.645 62.78c-.805-3.175-.862-7.408-.14-10.348z"
        fill="#265e98"
      />
    </svg>
  );
}

function Price2(props) {
  return (
        <svg
      width={394.375}
      height={291.69}
      viewBox="0 0 104.345 77.176"
      {...props}
    >
      <path
        d="M4.571 55.402c-9.92-11.694-2.412-39.147 10.26-48.371C22.4.97 44.657-1.206 53.952.633c21.586 2.132 31.852 11.13 46.229 28.017 4.365 5.546 6.06 17.21 1.311 22.932-6.544 9.715-21.328 15.803-31.004 21.952-21.833 10.89-50.593-4.854-65.917-18.132z"
fill="#ff9a9a"
      />
    </svg>
  )
}

function Price3(props) {
  return (
    <svg width={302.294} height={323.413} viewBox="0 0 79.982 85.57" {...props}>
      <path
        d="M45.192 85.085c-5-1.617-8.674-4.928-18.409-16.592-3.619-4.337-9.022-10.403-12.374-13.891-8.27-8.609-11.054-12.048-12.886-15.92-2.274-4.81-1.999-10.162.807-15.673 1.679-3.297 3.355-5.594 6.345-8.698C16.421 6.273 25.611 2.135 39.372.491c5.41-.647 16.71-.656 20.84-.016C70.765 2.11 75.985 5.937 78.495 13.88c1.257 3.98 1.483 6.068 1.486 13.759.006 13.127-1.642 22.677-5.476 31.75C70.07 69.884 61.63 80.236 54.623 83.778c-1.89.955-5.118 1.81-6.76 1.791-.669-.008-1.87-.226-2.67-.484z"
        fill="#265e98"
      />
    </svg>
  )
}


export { Price1, Price2, Price3 };
