import * as React from "react"

function SvgComponent(props) {
  return (
        <svg width={1325} height={323.598} viewBox="0 0 350.573 85.619" {...props}>
      <path
        d="M654.95 128.745c.964-3.919 3.957-9.074 8.197-14.115 4.46-5.302 21.69-23.529 25.87-27.364 6.833-6.273 11.928-9.24 18.046-10.51 7.17-1.49 16.373.842 24.338 6.166 3.059 2.045 7.707 6.458 9.952 9.45 7.718 10.283 10.497 25.968 7.159 40.398-2.874 12.424-10.159 22.446-19.854 27.317-2.247 1.129-7.47 2.824-10.725 3.48-7.345 1.483-21.665 2.3-28.565 1.632-11.669-1.132-19.242-4.517-25.942-11.597-4.104-4.336-7.06-9.483-8.335-14.508-.806-3.175-.863-7.409-.14-10.349z"
        fill="#265e98"
      />
      <path
        d="M508.106 61.364c-9.92-11.694-2.412-39.148 10.26-48.371 7.57-6.06 29.827-8.237 39.121-6.398 21.586 2.132 31.852 11.13 46.229 28.017 4.365 5.546 6.06 17.209 1.311 22.932-6.544 9.715-21.328 15.802-31.004 21.952-21.833 10.89-50.593-4.855-65.917-18.132z"
        fill="#d71010"
      />
      <path
        d="M461.849 237.158c-5.001-1.617-8.675-4.928-18.41-16.592-3.618-4.337-9.022-10.402-12.373-13.89-8.271-8.61-11.055-12.048-12.886-15.92-2.275-4.81-2-10.163.807-15.674 1.678-3.297 3.354-5.594 6.345-8.698 7.745-8.037 16.935-12.175 30.696-13.82 5.411-.647 16.711-.656 20.84-.016 10.554 1.635 15.774 5.462 18.284 13.407 1.258 3.98 1.483 6.067 1.486 13.758.006 13.127-1.641 22.677-5.476 31.75-4.435 10.494-12.875 20.846-19.882 24.388-1.89.955-5.119 1.81-6.761 1.791-.668-.008-1.87-.225-2.67-.484z"
        fill="#265e98"
      />
      <path
        d="M0 67.428s66.754-17.142 100.872-17.363c53.58-.347 105.205 23.297 158.751 25.21 30.41 1.087 90.95-7.847 90.95-7.847v18.19H0z"
        opacity={0.914}
        fill="#265e98"
        paintOrder="stroke fill markers"
      />
      <path
        d="M279.753 34.922c1.598 3.766.296 7.865.07 11.758-.495 4.63-.423 9.321-1.229 13.913-.72 3.013-3.088 5.448-5.982 6.474-4.01 1.608-8.456.751-12.484-.335-4.336-1.273-8.667-4.153-10.014-8.683-1.358-4.461.083-9.314 2.744-13 4.457-6.483 11.736-10.848 19.453-12.148 2.429-.318 5.393-.41 7.172 1.57l.146.22zM312.977 18.088c-1 .175-1.97-.104-4.864-1.397a67.006 67.006 0 00-3.574-1.465c-2.314-.849-3.153-1.239-3.884-1.804-.908-.702-1.459-1.756-1.612-3.085-.092-.796-.073-1.399.072-2.285.375-2.293 1.421-3.979 3.494-5.63.815-.649 2.666-1.747 3.414-2.025 1.912-.71 3.194-.482 4.49.799.649.641.918 1.02 1.774 2.495 1.462 2.518 2.254 4.51 2.635 6.623.441 2.444.21 5.249-.545 6.608-.203.367-.637.844-.909 1-.11.063-.331.138-.491.166z"
        fill="#265e98"
      />
      <path
        d="M147.592 36.555c.21 3.379-1.93 6.51-4.685 7.917-2.838 1.708-6.453 2.114-9.41.505-3.437-1.7-5.852-5.628-5.766-9.738-.026-4.301 2.497-8.757 6.443-9.969 3.886-1.152 8.113.737 10.737 3.882 1.662 2.02 2.716 4.68 2.681 7.403z"
        opacity={0.914}
        fill="#265e98"
        paintOrder="stroke fill markers"
      />
      <path
        d="M23.572 43.456c.099 1.819-1.341 3.484-3.022 3.81-1.269.197-2.496-.364-3.607-.935-1.268-.695-2.248-1.99-2.447-3.485-.31-2.011.701-4.27 2.563-5.05.844-.36 1.806-.232 2.607.187 1.116.55 2.046 1.44 2.845 2.405.654.868 1.087 1.952 1.06 3.068z"
        opacity={0.914}
        fill="#ff9a9a"
        paintOrder="stroke fill markers"
      />
      <path
        d="M214.27 61.566c-1 .175-1.97-.104-4.864-1.397a67.006 67.006 0 00-3.574-1.465c-2.314-.849-3.153-1.239-3.884-1.804-.908-.702-1.458-1.756-1.612-3.085-.091-.796-.072-1.399.073-2.285.375-2.293 1.421-3.979 3.494-5.63.815-.649 2.666-1.747 3.414-2.025 1.912-.71 3.194-.482 4.489.799.65.641.918 1.02 1.775 2.495 1.462 2.518 2.254 4.51 2.635 6.623.44 2.444.21 5.249-.545 6.608-.204.367-.638.844-.91 1-.11.063-.33.138-.49.166z"
        fill="#ff9a9a"
      />
      <path
        d="M74.023 28.537c-1.003.153-1.967-.146-4.832-1.503a67.004 67.004 0 00-3.541-1.542c-2.295-.9-3.125-1.307-3.844-1.888-.893-.722-1.42-1.787-1.544-3.12-.074-.797-.042-1.4.122-2.282.425-2.285 1.508-3.947 3.616-5.553.829-.63 2.704-1.688 3.457-1.95 1.927-.668 3.204-.412 4.471.896.635.656.896 1.04 1.72 2.534 1.406 2.549 2.155 4.558 2.49 6.679.387 2.452.095 5.252-.69 6.594-.21.362-.655.83-.93.98-.111.06-.334.13-.495.155z"
        fill="#265e98"
      />
      <path
        d="M185.218 52.312c.114 1.833-1.047 3.532-2.542 4.295-1.54.927-3.5 1.147-5.104.274-1.865-.922-3.174-3.053-3.128-5.282-.014-2.334 1.354-4.75 3.495-5.408 2.108-.625 4.4.4 5.824 2.106.902 1.096 1.473 2.538 1.455 4.015z"
        opacity={0.914}
        fill="#265e98"
        paintOrder="stroke fill markers"
      />
    </svg>
  )
}

export default SvgComponent

