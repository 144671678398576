import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import i18n from "./../my-i18next.js";
import { withTranslation } from "react-i18next";
import SVGHeader from "./_svg/_SVGHeader.js";
import IlluLaptopHeader from "./_svg/_IlluHeader.js";

class Header extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      onTop: true,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("scroll", (w) => {
      let scrollY = window.scrollY;
      // less concise but not reloading the dom
      if (scrollY > 30 && this._isMounted) {
        this.setState({
          onTop: false,
        });
      }
      if (scrollY < 30 && this._isMounted) {
        this.setState({
          onTop: true,
        });
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    const { t } = this.props;
    return (
      <div id="header">
        <div className="header-central">
          <div className="header-left">
            <h1 className="header-left-title">Seo-Metrics.net 
              <br/> <small className="header-left-prev">{t("prev-vautours")}</small>
            </h1>
            <span className="header-left-body" arialabel="emoji free">
              {t("header-sub-title")} ⏱️
              <br />
            </span>
            <div className="header-left-cta">
              <a
                href="https://app.vautours.net"
                target="_blank"
                rel="noreferrer"
                className="btn btn-get-started">
                {t("header-cta-gs")}
              </a>
              <span className="no-card-required" arialabel="emoji credit card">
      {

                //{t("header-no-credit-card")} 💳
      }
              </span>
            </div>
          </div>
          <div className="header-right">
            <div className="header-illustration">
              <IlluLaptopHeader className="illu-laptop-header" />{" "}
              <img
                className="illu-phone-header"
                alt="iphone-mockup"
                src="/iphone-mockup.png"
              />
            </div>
          </div>
        </div>
        <div className="bg-img">
          {" "}
          <SVGHeader className="svg-header" />{" "}
        </div>
        <div
          className={`scrollDown ${
            this.state.onTop ? "onTop" : "hasScrolled"
          }`}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Header);
