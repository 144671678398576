import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class PrivacyPolicy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { t } = this.props;
    return (
      <div id="PrivacyPolicy">
        <div style={{ padding: "20px" }} id="PrivacyPolicy-body">
          <div>
            <div>
              <strong>
                <span style={{ fontSize: "26px" }}>
                  <span data-custom-class="title">PRIVACY NOTICE</span>
                </span>
              </strong>
            </div>
            <div>
              <br />
            </div>
            <div>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <strong>
                  <span style={{ fontSize: "15px" }}>
                    <span data-custom-class="subtitle">
                      Last updated <bdt className="question">June 10, 2021</bdt>
                    </span>
                  </span>
                </strong>
              </span>
            </div>
            <div>
              <br />
            </div>
            <div>
              <br />
            </div>
            <div>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    Thank you for choosing to be part of our community at{" "}
                    <bdt className="question">Seo-Metrics.Net</bdt>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>{" "}
                    ("
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                    <strong>Company</strong>
                  </span>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor">
                              <span data-custom-class="body_text" />
                            </bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    ", "<strong>we</strong>", "<strong>us</strong>", "
                    <strong>our</strong>"). We are committed to protecting your
                    personal information and your right to privacy. If you have
                    any questions or concerns about this privacy notice, or our
                    practices with regards to your personal information, please
                    contact us at{" "}
                    <bdt className="question">francois@vautours.net</bdt>.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <br />
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span data-custom-class="body_text">
                    When you{" "}
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                    visit our website{" "}
                    <bdt className="question">
                      <a
                        href="https://Seo-Metrics.Net"
                        target="_blank"
                        data-custom-class="link">
                        https://Seo-Metrics.Net
                      </a>
                    </bdt>{" "}
                    (the "<strong>Website</strong>"),{" "}
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                        <bdt className="block-component" />
                      </span>
                    </span>{" "}
                    and more generally, use any of our services (the "
                    <strong>Services</strong>", which include the&nbsp;
                  </span>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component">
                            <span data-custom-class="body_text" />
                          </bdt>
                          Website
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </span>
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span data-custom-class="body_text">
                  ), we appreciate that you are trusting us with your personal
                  information. We take your privacy very seriously. In this
                  privacy notice, we seek to explain to you in the clearest way
                  possible what information we collect, how we use it and what
                  rights you have in relation to it. We hope you take some time
                  to read through it carefully, as it is important. If there are
                  any terms in this privacy notice that you do not agree with,
                  please discontinue use of our Services immediately.
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <br />
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      This privacy notice applies to all information collected
                      through our Services (which, as described above, includes
                      our&nbsp;
                    </span>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component">
                          <span data-custom-class="body_text" />
                        </bdt>
                        Website
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                        <bdt className="block-component">
                          <bdt className="block-component" />
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <span data-custom-class="body_text">
                ), as well as, any related services, sales, marketing or events.
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <br />
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        Please read this privacy notice carefully as it will
                        help you understand what we do with the information that
                        we collect.
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <br />
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span data-custom-class="heading_1">
                        TABLE OF CONTENTS
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <br />
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#infocollect">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    1. WHAT INFORMATION DO WE COLLECT?
                  </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#infouse">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    2. HOW DO WE USE YOUR INFORMATION?
                  </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a data-custom-class="link" href="#infoshare">
                    3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                  </a>
                </span>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="block-component" />
                      </span>
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#sociallogins">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                      </span>
                    </span>
                  </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#intltransfers">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                  </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#3pwebsites">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    6. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                  </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#inforetain">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    7. HOW LONG DO WE KEEP YOUR INFORMATION?
                  </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#infosafe">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#infominors">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    9. DO WE COLLECT INFORMATION FROM MINORS?
                  </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a data-custom-class="link" href="#privacyrights">
                    10. WHAT ARE YOUR PRIVACY RIGHTS?
                  </a>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#DNT">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    11. CONTROLS FOR DO-NOT-TRACK FEATURES
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#caresidents">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#policyupdates">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    13. DO WE MAKE UPDATES TO THIS NOTICE?
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <a data-custom-class="link" href="#contact">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
              </a>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <a data-custom-class="link" href="#request">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  15. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT
                  FROM YOU?
                </span>
              </a>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div id="infocollect" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                      <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                        <strong>
                          <span data-custom-class="heading_1">
                            1. WHAT INFORMATION DO WE COLLECT?
                          </span>
                        </strong>
                        <span data-custom-class="heading_1">
                          <bdt className="block-component">
                            <span data-custom-class="body_text" />
                          </bdt>
                        </span>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span
                data-custom-class="heading_2"
                style={{ color: "rgb(0, 0, 0)" }}>
                <span style={{ fontSize: "15px" }}>
                  <strong>
                    <u>
                      <br />
                    </u>
                  </strong>
                  <strong>Information automatically collected</strong>
                </span>
              </span>
            </div>
            <div>
              <div>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span data-custom-class="body_text">
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span data-custom-class="body_text">
                            <strong>
                              <em>In Short:&nbsp;</em>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span data-custom-class="body_text">
                            <strong>
                              <em>&nbsp;</em>
                            </strong>
                            <em>
                              Some information — such as your Internet Protocol
                              (IP) address and/or browser and device
                              characteristics — is collected automatically when
                              you visit our{" "}
                              <span data-custom-class="body_text">
                                <em>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                        Website
                                        <bdt className="statement-end-if-in-editor" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                      </span>
                                    </span>
                                  </span>
                                </em>
                              </span>
                              .
                            </em>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    We automatically collect certain information when you visit,
                    use or navigate the{" "}
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        Website
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                        <bdt className="block-component" />
                      </span>
                    </span>
                    . This information does not reveal your specific identity
                    (like your name or contact information) but may include
                    device and usage information, such as your IP address,
                    browser and device characteristics, operating system,
                    language preferences, referring URLs, device name, country,
                    location, information about how and when you use our{" "}
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        Website
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                        <bdt className="block-component" />
                      </span>
                    </span>{" "}
                    and other technical information. This information is
                    primarily needed to maintain the security and operation of
                    our{" "}
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        Website
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                        <bdt className="block-component" />
                      </span>
                    </span>
                    , and for our internal analytics and reporting purposes.
                    <bdt className="block-component" />
                  </span>
                  <bdt className="block-component" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    The information we collect includes:
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <em>Log and Usage Data.</em> Log and usage data is
                      service-related, diagnostic, usage and performance
                      information our servers automatically collect when you
                      access or use our{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                                Website
                                <bdt className="statement-end-if-in-editor" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>{" "}
                      and which we record in log files. Depending on how you
                      interact with us, this log data may include your IP
                      address, device information, browser type and settings and
                      information about your activity in the{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                                Website
                                <bdt className="statement-end-if-in-editor" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                        </span>
                        &nbsp;
                      </span>
                      (such as the date/time stamps associated with your usage,
                      pages and files viewed, searches and other actions you
                      take such as which features you use), device event
                      information (such as system activity, error reports
                      (sometimes called 'crash dumps') and hardware settings).
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <bdt className="block-component">
                <span style={{ fontSize: "15px" }} />
              </bdt>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <em>Device Data.</em> We collect device data such as
                      information about your computer, phone, tablet or other
                      device you use to access the{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                          Website
                          <bdt className="statement-end-if-in-editor" />
                          <bdt className="block-component" />
                          <bdt className="block-component" />
                        </span>
                      </span>
                      . Depending on the device used, this device data may
                      include information such as your IP address (or proxy
                      server), device and application identification numbers,
                      location, browser type, hardware model Internet service
                      provider and/or mobile carrier, operating system and
                      system configuration information.
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <bdt className="block-component">
                <span style={{ fontSize: "15px" }} />
              </bdt>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <em>Location Data.</em> We collect location data such as
                      information about your device's location, which can be
                      either precise or imprecise. How much information we
                      collect depends on the type and settings of the device you
                      use to access the{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                                Website
                                <bdt className="statement-end-if-in-editor" />
                                <bdt className="block-component" />
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                      . For example, we may use GPS and other technologies to
                      collect geolocation data that tells us your current
                      location (based on your IP address). You can opt out of
                      allowing us to collect this information either by refusing
                      access to the information or by disabling your Location
                      setting on your device. Note however, if you choose to opt
                      out, you may not be able to use certain aspects of the
                      Services.
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <bdt className="block-component">
                <span style={{ fontSize: "15px" }} />
              </bdt>
              <bdt className="statement-end-if-in-editor" />
              <span data-custom-class="body_text">
                <span style={{ fontSize: "15px" }}>
                  <bdt className="statement-end-if-in-editor">
                    <bdt className="statement-end-if-in-editor">
                      <span style={{ fontSize: "15px" }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: "15px" }}>
                            <span data-custom-class="body_text">
                              <bdt className="statement-end-if-in-editor">
                                <bdt className="statement-end-if-in-editor">
                                  <bdt className="block-component" />
                                </bdt>
                              </bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                        <span data-custom-class="body_text">
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}>
                            <span data-custom-class="body_text">
                              <bdt className="statement-end-if-in-editor">
                                <bdt className="block-component" />
                              </bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </bdt>
                  </bdt>
                </span>
              </span>
              <bdt className="block-component" />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div id="infouse" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                      <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                        <strong>
                          <span data-custom-class="heading_1">
                            2. HOW DO WE USE YOUR INFORMATION?
                          </span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span data-custom-class="body_text">
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span data-custom-class="body_text">
                            <strong>
                              <em>In Short: &nbsp;</em>
                            </strong>
                            <em>
                              We process your information for purposes based on
                              legitimate business interests, the fulfillment of
                              our contract with you, compliance with our legal
                              obligations, and/or your consent.
                            </em>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    We use personal information collected via our{" "}
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                        Website
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                        <bdt className="block-component" />
                      </span>
                    </span>{" "}
                    for a variety of business purposes described below. We
                    process your personal information for these purposes in
                    reliance on our legitimate business interests, in order to
                    enter into or perform a contract with you, with your
                    consent, and/or for compliance with our legal obligations.
                    We indicate the specific processing grounds we rely on next
                    to each purpose listed below.
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    We use the information we collect or receive:
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        To facilitate account creation and logon process.
                      </strong>{" "}
                      If you choose to link your account with us to a
                      third-party account (such as your Google or Facebook
                      account), we use the information you allowed us to collect
                      from those third parties to facilitate account creation
                      and logon process for the performance of the contract.
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <bdt className="block-component" />
                            </span>
                          </span>
                          &nbsp;
                        </span>
                      </span>
                      See the section below headed "
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                        <span data-custom-class="body_text">
                          <a data-custom-class="link" href="#sociallogins">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                              </span>
                            </span>
                          </a>
                        </span>
                      </span>
                      " for further information.
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="statement-end-if-in-editor">
                                          <span
                                            style={{
                                              fontSize: "15px",
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span
                                                    style={{
                                                      fontSize: "15px",
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                        }}>
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}>
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}>
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </bdt>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>To post testimonials.</strong> We post
                      testimonials on our{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <bdt className="block-component" />
                                  Website
                                  <bdt className="statement-end-if-in-editor" />
                                  <bdt className="block-component" />
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>{" "}
                      that may contain personal information. Prior to posting a
                      testimonial, we will obtain your consent to use your name
                      and the content of the testimonial. If you wish to update,
                      or delete your testimonial, please contact us at{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                            <bdt className="question">
                              francois@vautours.net
                            </bdt>
                            <bdt className="else-block" />
                          </span>
                        </span>
                      </span>{" "}
                      and be sure to include your name, testimonial location,
                      and contact information.
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span data-custom-class="body_text">
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: "15px" }}>
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span data-custom-class="body_text">
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>Request feedback.&nbsp;</strong>We may use your
                      information to request feedback and to contact you about
                      your use of our{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <bdt className="block-component" />
                                  Website
                                  <bdt className="statement-end-if-in-editor" />
                                  <bdt className="block-component" />
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </span>
                            .
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        color: "rgb(89, 89, 89)",
                                      }}>
                                      <span data-custom-class="body_text">
                                        <span
                                          style={{
                                            fontSize: "15px",
                                            color: "rgb(89, 89, 89)",
                                          }}>
                                          <span
                                            style={{
                                              fontSize: "15px",
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span data-custom-class="body_text">
                                              <span
                                                style={{ fontSize: "15px" }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}>
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}>
                                                        <span data-custom-class="body_text">
                                                          <bdt className="statement-end-if-in-editor" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>To enable user-to-user communications.</strong> We
                      may use your information in order to enable user-to-user
                      communications with each user's consent.
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="statement-end-if-in-editor" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>To manage user accounts.&nbsp;</strong>We may use
                      your information for the purposes of managing our account
                      and keeping it in working order.
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <bdt className="block-component" />
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        To send administrative information to you.&nbsp;
                      </strong>
                      We may use your personal information to send you product,
                      service and new feature information and/or information
                      about changes to our terms, conditions, and policies.
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <bdt className="block-component" />
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>To protect our Services.&nbsp;</strong>We may use
                      your information as part of our efforts to keep our{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <bdt className="block-component" />
                                  Website
                                  <bdt className="statement-end-if-in-editor" />
                                  <bdt className="block-component" />
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>{" "}
                      safe and secure (for example, for fraud monitoring and
                      prevention).
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span data-custom-class="body_text">
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span data-custom-class="body_text">
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <bdt className="block-component" />
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        To enforce our terms, conditions and policies for
                        business purposes, to comply with legal and regulatory
                        requirements or in connection with our contract.
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        color: "rgb(89, 89, 89)",
                                      }}>
                                      <span
                                        style={{
                                          fontSize: "15px",
                                          color: "rgb(89, 89, 89)",
                                        }}>
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <bdt className="block-component" />
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          To respond to legal requests and prevent harm.&nbsp;
                        </strong>
                        If we receive a subpoena or other legal request, we may
                        need to inspect the data we hold to determine how to
                        respond.
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <p style={{ fontSize: "15px" }}>
              <bdt className="block-component" />
            </p>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>Fulfill and manage your orders.&nbsp;</strong>We
                        may use your information to fulfill and manage your
                        orders, payments, returns, and exchanges made through
                        the{" "}
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: "15px" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                    Website
                                    <bdt className="statement-end-if-in-editor" />
                                    <bdt className="block-component" />
                                    <bdt className="block-component">.</bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <p style={{ fontSize: "15px" }}>
              <bdt className="block-component" />
            </p>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          Administer prize draws and competitions.
                        </strong>{" "}
                        We may use your information to administer prize draws
                        and competitions when you elect to participate in our
                        competitions.
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <p style={{ fontSize: "15px" }}>
              <bdt className="block-component" />
            </p>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          To deliver and facilitate delivery of services to the
                          user.
                        </strong>{" "}
                        We may use your information to provide you with the
                        requested service.
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <p style={{ fontSize: "15px" }}>
              <bdt className="block-component" />
            </p>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          To respond to user inquiries/offer support to users.
                        </strong>{" "}
                        We may use your information to respond to your inquiries
                        and solve any potential issues you might have with the
                        use of our Services.
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <bdt className="block-component">
                <span style={{ fontSize: "15px" }} />
              </bdt>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        To send you marketing and promotional communications.
                      </strong>{" "}
                      We and/or our third-party marketing partners may use the
                      personal information you send to us for our marketing
                      purposes, if this is in accordance with your marketing
                      preferences. For example, when expressing an interest in
                      obtaining information about us or our{" "}
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <bdt className="block-component" />
                                  Website
                                  <bdt className="statement-end-if-in-editor" />
                                  <bdt className="block-component" />
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                      , subscribing to marketing or otherwise contacting us, we
                      will collect personal information from you. You can
                      opt-out of our marketing emails at any time (see the "
                      <a data-custom-class="link" href="#privacyrights">
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            WHAT ARE YOUR PRIVACY RIGHTS?
                          </span>
                        </span>
                      </a>
                      " below).
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="statement-end-if-in-editor" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <bdt className="block-component">
                <span style={{ fontSize: "15px" }} />
              </bdt>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>Deliver targeted advertising to you.</strong>
                    </span>
                    <span data-custom-class="body_text">
                      &nbsp;We may use your information to develop and display
                      personalized content and advertising (and work with third
                      parties who do so) tailored to your interests and/or
                      location and to measure its effectiveness.
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <bdt className="block-component">
                <span style={{ fontSize: "15px" }} />
              </bdt>
              <div>
                <span style={{ fontSize: "15px" }}>
                  <bdt className="block-component" />
                </span>
                <bdt className="statement-end-if-in-editor">
                  <span style={{ fontSize: "15px" }} />
                </bdt>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div id="infoshare" style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                          <strong>
                            <span data-custom-class="heading_1">
                              3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        <em>In Short:</em>
                      </strong>
                      <em>
                        &nbsp; We only share information with your consent, to
                        comply with laws, to provide you with services, to
                        protect your rights, or to fulfill business obligations.
                      </em>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      We may process or share your data that we hold based on
                      the following legal basis:
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>Consent:</strong> We may process your data if
                        you have given us specific consent to use your personal
                        information for a specific purpose.
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span data-custom-class="body_text">
                              <bdt className="statement-end-if-in-editor" />
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span data-custom-class="body_text">
                            <bdt className="statement-end-if-in-editor">
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </bdt>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>Legitimate Interests:</strong> We may process
                        your data when it is reasonably necessary to achieve our
                        legitimate business interests.
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>Performance of a Contract:</strong> Where we
                        have entered into a contract with you, we may process
                        your personal information to fulfill the terms of our
                        contract.
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>Legal Obligations:</strong> We may disclose your
                        information where we are legally required to do so in
                        order to comply with applicable law, governmental
                        requests, a judicial proceeding, court order, or legal
                        process, such as in response to a court order or a
                        subpoena (including in response to public authorities to
                        meet national security or law enforcement requirements).
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>Vital Interests:</strong> We may disclose your
                        information where we believe it is necessary to
                        investigate, prevent, or take action regarding potential
                        violations of our policies, suspected fraud, situations
                        involving potential threats to the safety of any person
                        and illegal activities, or as evidence in litigation in
                        which we are involved.
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      More specifically, we may need to process your data or
                      share your personal information in the following
                      situations:
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>Business Transfers.</strong> We may share or
                        transfer your information in connection with, or during
                        negotiations of, any merger, sale of company assets,
                        financing, or acquisition of all or a portion of our
                        business to another company.
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <div>
                  <span style={{ fontSize: "15px" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                  <div>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                    <div>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ fontSize: "15px" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                    <ul>
                      <li style={{ lineHeight: "1.5" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span data-custom-class="body_text">
                              <strong>Affiliates.</strong> We may share your
                              information with our affiliates, in which case we
                              will require those affiliates to honor this
                              privacy notice. Affiliates include our parent
                              company and any subsidiaries, joint venture
                              partners or other companies that we control or
                              that are under common control with us.
                              <span style={{ fontSize: "15px" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}>
                                        <bdt className="statement-end-if-in-editor" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </li>
                    </ul>
                    <div>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                    </div>
                    <ul>
                      <li style={{ lineHeight: "1.5" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            color: "rgb(89, 89, 89)",
                          }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span data-custom-class="body_text">
                              <strong>Business Partners.&nbsp;</strong>We may
                              share your information with our business partners
                              to offer you certain products, services or
                              promotions.
                            </span>
                          </span>
                        </span>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: "15px" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </li>
                    </ul>
                    <div>
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                      <div>
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: "15px" }}>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                        </span>
                        <div style={{ lineHeight: "1.5" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="block-component" />
                          </span>
                        </div>
                        <div>
                          <span
                            style={{
                              color: "rgb(89, 89, 89)",
                              fontSize: "15px",
                            }}>
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <bdt className="block-component">
                                      <span data-custom-class="heading_1" />
                                    </bdt>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}>
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}>
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                        fontSize: "15px",
                                      }}>
                                      <span style={{ fontSize: "15px" }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}>
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div id="sociallogins" style={{ lineHeight: "1.5" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    id="control"
                                    style={{ color: "rgb(0, 0, 0)" }}>
                                    <strong>
                                      <span data-custom-class="heading_1">
                                        4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                                      </span>
                                    </strong>
                                    &nbsp;
                                  </span>
                                  &nbsp;
                                </span>
                                &nbsp;
                              </span>
                              &nbsp;
                            </span>
                            &nbsp;
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                <strong>
                                  <em>In Short: &nbsp;</em>
                                </strong>
                                <em>
                                  If you choose to register or log in to our
                                  services using a social media account, we may
                                  have access to certain information about you.
                                </em>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                Our{" "}
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: "15px" }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: "15px" }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span data-custom-class="body_text">
                                                  <bdt className="block-component" />
                                                  Website
                                                  <bdt className="statement-end-if-in-editor" />
                                                  <bdt className="block-component" />
                                                  <bdt className="block-component" />
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>{" "}
                                offers you the ability to register and login
                                using your third-party social media account
                                details (like your Facebook or Twitter logins).
                                Where you choose to do this, we will receive
                                certain profile information about you from your
                                social media provider. The profile information
                                we receive may vary depending on the social
                                media provider concerned, but will often include
                                your name, email address, friends list, profile
                                picture as well as other information you choose
                                to make public on such social media platform.{" "}
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                We will use the information we receive only for
                                the purposes that are described in this privacy
                                notice or that are otherwise made clear to you
                                on the relevant{" "}
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                                Website
                                                <bdt className="statement-end-if-in-editor" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                . Please note that we do not control, and are
                                not responsible for, other uses of your personal
                                information by your third-party social media
                                provider. We recommend that you review their
                                privacy notice to understand how they collect,
                                use and share your personal information, and how
                                you can set your privacy preferences on their
                                sites and apps.
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}>
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}>
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                        fontSize: "15px",
                                      }}>
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: "15px",
                                        }}>
                                        <span style={{ fontSize: "15px" }}>
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span style={{ fontSize: "15px" }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span data-custom-class="body_text">
                                                  <bdt className="statement-end-if-in-editor" />
                                                </span>
                                                <bdt className="block-component">
                                                  <span data-custom-class="body_text">
                                                    <bdt className="block-component" />
                                                  </span>
                                                </bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div id="intltransfers" style={{ lineHeight: "1.5" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    id="control"
                                    style={{ color: "rgb(0, 0, 0)" }}>
                                    <strong>
                                      <span data-custom-class="heading_1">
                                        5. IS YOUR INFORMATION TRANSFERRED
                                        INTERNATIONALLY?
                                      </span>
                                    </strong>
                                    &nbsp;
                                  </span>
                                  &nbsp;
                                </span>
                                &nbsp;
                              </span>
                              &nbsp;
                            </span>
                            &nbsp;
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                <strong>
                                  <em>In Short: &nbsp;</em>
                                </strong>
                                <em>
                                  We may transfer, store, and process your
                                  information in countries other than your own.
                                </em>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                Our servers are located in
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="forloop-component" />
                                    </span>
                                  </span>
                                </span>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span data-custom-class="body_text">
                                      . If you are accessing our&nbsp;
                                    </span>
                                  </span>
                                </span>
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: "15px",
                                        }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{ fontSize: "15px" }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component" />
                                                      Website
                                                      <bdt className="statement-end-if-in-editor" />
                                                      <bdt className="block-component" />
                                                      <bdt className="block-component" />
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span data-custom-class="body_text">
                                      &nbsp;from outside
                                    </span>
                                  </span>
                                </span>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: "15px" }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}>
                                          <span data-custom-class="body_text">
                                            <bdt className="forloop-component" />
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span data-custom-class="body_text">
                                      , please be aware that your information
                                      may be transferred to, stored, and
                                      processed by us in our facilities and by
                                      those third parties with whom we may share
                                      your personal information (see "
                                    </span>
                                  </span>
                                </span>
                                <a data-custom-class="link" href="#infoshare">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      WILL YOUR INFORMATION BE SHARED WITH
                                      ANYONE?
                                    </span>
                                  </span>
                                </a>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span data-custom-class="body_text">
                                      " above), in
                                    </span>
                                  </span>
                                </span>
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="forloop-component" />
                                    </span>
                                  </span>
                                </span>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span data-custom-class="body_text">
                                      &nbsp;and other countries.
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                If you are a resident in the European Economic
                                Area (EEA) or United Kingdom (UK), then these
                                countries may not necessarily have data
                                protection laws or other similar laws as
                                comprehensive as those in your country. We will
                                however take all necessary measures to protect
                                your personal information in accordance with
                                this privacy notice and applicable law.
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <span style={{ fontSize: "15px" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}>
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                        fontSize: "15px",
                                      }}>
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: "15px",
                                        }}>
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                            fontSize: "15px",
                                          }}>
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                              fontSize: "15px",
                                            }}>
                                            <span style={{ fontSize: "15px" }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <span
                                                  style={{ fontSize: "15px" }}>
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}>
                                                    <span data-custom-class="body_text">
                                                      <bdt className="statement-end-if-in-editor">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: "15px",
                                                          }}>
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "15px",
                                                            }}>
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "15px",
                                                              }}>
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize:
                                                                    "15px",
                                                                }}>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize:
                                                                      "15px",
                                                                  }}>
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}>
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                        }}>
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}>
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </bdt>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              &nbsp;
                            </span>
                          </span>
                        </div>
                        <div>
                          <span
                            id="3pwebsites"
                            style={{ color: "rgb(127, 127, 127)" }}>
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    id="control"
                                    style={{ color: "rgb(0, 0, 0)" }}>
                                    <strong>
                                      <span data-custom-class="heading_1">
                                        6. WHAT IS OUR STANCE ON THIRD-PARTY
                                        WEBSITES?
                                      </span>
                                    </strong>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                <strong>
                                  <em>In Short:&nbsp;</em>
                                </strong>
                                <em>
                                  &nbsp;We are not responsible for the safety of
                                  any information that you share with
                                  third-party providers who advertise, but are
                                  not affiliated with, our Website.
                                </em>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                The{" "}
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                                Website
                                                <bdt className="statement-end-if-in-editor" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>{" "}
                                may contain advertisements from third parties
                                that are not affiliated with us and which may
                                link to other websites, online services or
                                mobile applications. We cannot guarantee the
                                safety and privacy of data you provide to any
                                third parties. Any data collected by third
                                parties is not covered by this privacy notice.
                                We are not responsible for the content or
                                privacy and security practices and policies of
                                any third parties, including other websites,
                                services or applications that may be linked to
                                or from the{" "}
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                                Website
                                                <bdt className="statement-end-if-in-editor" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                . You should review the policies of such third
                                parties and contact them directly to respond to
                                your questions.
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}>
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}>
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                        fontSize: "15px",
                                      }}>
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: "15px",
                                        }}>
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                            fontSize: "15px",
                                          }}>
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <bdt className="statement-end-if-in-editor">
                                                  <span data-custom-class="body_text" />
                                                </bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div id="inforetain" style={{ lineHeight: "1.5" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    id="control"
                                    style={{ color: "rgb(0, 0, 0)" }}>
                                    <strong>
                                      <span data-custom-class="heading_1">
                                        7. HOW LONG DO WE KEEP YOUR INFORMATION?
                                      </span>
                                    </strong>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                <strong>
                                  <em>In Short:&nbsp;</em>
                                </strong>
                                <em>
                                  &nbsp;We keep your information for as long as
                                  necessary to fulfill the purposes outlined in
                                  this privacy notice unless otherwise required
                                  by law.
                                </em>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                We will only keep your personal information for
                                as long as it is necessary for the purposes set
                                out in this privacy notice, unless a longer
                                retention period is required or permitted by law
                                (such as tax, accounting or other legal
                                requirements). No purpose in this notice will
                                require us keeping your personal information for
                                longer than{" "}
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                </span>
                                <bdt className="block-component" />
                                the period of time in which users have an
                                account with us
                                <bdt className="block-component" />
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="else-block" />
                                    </span>
                                  </span>
                                </span>
                                .
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                When we have no ongoing legitimate business need
                                to process your personal information, we will
                                either delete or anonymize such information, or,
                                if this is not possible (for example, because
                                your personal information has been stored in
                                backup archives), then we will securely store
                                your personal information and isolate it from
                                any further processing until deletion is
                                possible.
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div id="infosafe" style={{ lineHeight: "1.5" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    id="control"
                                    style={{ color: "rgb(0, 0, 0)" }}>
                                    <strong>
                                      <span data-custom-class="heading_1">
                                        8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                                      </span>
                                    </strong>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                <strong>
                                  <em>In Short:&nbsp;</em>
                                </strong>
                                <em>
                                  &nbsp;We aim to protect your personal
                                  information through a system of organizational
                                  and technical security measures.
                                </em>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                We have implemented appropriate technical and
                                organizational security measures designed to
                                protect the security of any personal information
                                we process. However, despite our safeguards and
                                efforts to secure your information, no
                                electronic transmission over the Internet or
                                information storage technology can be guaranteed
                                to be 100% secure, so we cannot promise or
                                guarantee that hackers, cybercriminals, or other
                                unauthorized third parties will not be able to
                                defeat our security, and improperly collect,
                                access, steal, or modify your information.
                                Although we will do our best to protect your
                                personal information, transmission of personal
                                information to and from our{" "}
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                                Website
                                                <bdt className="statement-end-if-in-editor" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>{" "}
                                is at your own risk. You should only access the{" "}
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                                Website
                                                <bdt className="statement-end-if-in-editor" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>{" "}
                                within a secure environment.
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div id="infominors" style={{ lineHeight: "1.5" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    id="control"
                                    style={{ color: "rgb(0, 0, 0)" }}>
                                    <strong>
                                      <span data-custom-class="heading_1">
                                        9. DO WE COLLECT INFORMATION FROM
                                        MINORS?
                                      </span>
                                    </strong>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                <strong>
                                  <em>In Short:</em>
                                </strong>
                                <em>
                                  &nbsp; We do not knowingly collect data from
                                  or market to children under 18 years of age.
                                </em>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                We do not knowingly solicit data from or market
                                to children under 18 years of age. By using the{" "}
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                                Website
                                                <bdt className="statement-end-if-in-editor" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                , you represent that you are at least 18 or that
                                you are the parent or guardian of such a minor
                                and consent to such minor dependent’s use of the{" "}
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                                Website
                                                <bdt className="statement-end-if-in-editor" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                . If we learn that personal information from
                                users less than 18 years of age has been
                                collected, we will deactivate the account and
                                take reasonable measures to promptly delete such
                                data from our records. If you become aware of
                                any data we may have collected from children
                                under age 18, please contact us at{" "}
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                    <bdt className="question">
                                      francois@vautours.net
                                    </bdt>
                                    <bdt className="else-block" />
                                  </span>
                                </span>
                                .
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}>
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div id="privacyrights" style={{ lineHeight: "1.5" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span
                              style={{
                                color: "rgb(89, 89, 89)",
                                fontSize: "15px",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    id="control"
                                    style={{ color: "rgb(0, 0, 0)" }}>
                                    <strong>
                                      <span data-custom-class="heading_1">
                                        10. WHAT ARE YOUR PRIVACY RIGHTS?
                                      </span>
                                    </strong>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                <strong>
                                  <em>In Short:</em>
                                </strong>
                                <em>
                                  &nbsp;{" "}
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span data-custom-class="body_text">
                                        <em>
                                          <bdt className="block-component" />
                                        </em>
                                      </span>
                                    </span>
                                  </span>
                                  You may review, change, or terminate your
                                  account at any time.
                                </em>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: "15px" }}>
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            &nbsp;
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                If you are a resident in the EEA or UK and you
                                believe we are unlawfully processing your
                                personal information, you also have the right to
                                complain to your local data protection
                                supervisory authority. You can find their
                                contact details here:{" "}
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(48, 48, 241)" }}>
                                        <span data-custom-class="body_text">
                                          <a
                                            data-custom-class="link"
                                            href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                            rel="noopener noreferrer"
                                            target="_blank">
                                            <span style={{ fontSize: "15px" }}>
                                              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                            </span>
                                          </a>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                .
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                If you are a resident in Switzerland, the
                                contact details for the data protection
                                authorities are available here:{" "}
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(48, 48, 241)" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <a
                                              data-custom-class="link"
                                              href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                              rel="noopener noreferrer"
                                              target="_blank">
                                              https://www.edoeb.admin.ch/edoeb/en/home.html
                                            </a>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                .<bdt className="block-component" />
                                <bdt className="block-component" />
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span data-custom-class="body_text">
                                If you have questions or comments about your
                                privacy rights, you may email us at{" "}
                                <span style={{ fontSize: "15px" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="question">
                                        francois+privacypolicy@vautours.net
                                      </bdt>
                                    </span>
                                  </span>
                                </span>
                                .
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: "15px" }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}>
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span
                                                style={{ fontSize: "15px" }}>
                                                <bdt className="statement-end-if-in-editor" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span
                            data-custom-class="heading_2"
                            style={{ color: "rgb(0, 0, 0)" }}>
                            <span style={{ fontSize: "15px" }}>
                              <strong>
                                <u>
                                  <br />
                                </u>
                              </strong>
                              <strong>Account Information</strong>
                            </span>
                          </span>
                        </div>
                        <div>
                          <div>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: "15px",
                                }}>
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        color: "rgb(89, 89, 89)",
                                      }}>
                                      <span data-custom-class="body_text">
                                        If you would at any time like to review
                                        or change the information in your
                                        account or terminate your account, you
                                        can:
                                        <bdt className="forloop-component" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <ul>
                            <li style={{ lineHeight: "1.5" }}>
                              <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: "15px",
                                  }}>
                                  <span data-custom-class="body_text">
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        color: "rgb(89, 89, 89)",
                                      }}>
                                      <span
                                        style={{
                                          fontSize: "15px",
                                          color: "rgb(89, 89, 89)",
                                        }}>
                                        <span data-custom-class="body_text">
                                          <bdt className="question">
                                            Contact us using the contact
                                            information provided.
                                          </bdt>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                          </ul>
                          <div>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: "15px",
                                }}>
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        color: "rgb(89, 89, 89)",
                                      }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="forloop-component" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span data-custom-class="body_text">
                                  Upon your request to terminate your account,
                                  we will deactivate or delete your account and
                                  information from our active databases.
                                  However, we may retain some information in our
                                  files to prevent fraud, troubleshoot problems,
                                  assist with any investigations, enforce our
                                  Terms of Use and/or comply with applicable
                                  legal requirements.
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{ fontSize: "15px" }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}>
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: "15px" }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}>
                                                <bdt className="statement-end-if-in-editor" />
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span data-custom-class="body_text">
                                  <strong>
                                    <u>Opting out of email marketing:</u>
                                  </strong>{" "}
                                  You can unsubscribe from our marketing email
                                  list at any time by clicking on the
                                  unsubscribe link in the emails that we send or
                                  by contacting us using the details provided
                                  below. You will then be removed from the
                                  marketing email list — however, we may still
                                  communicate with you, for example to send you
                                  service-related emails that are necessary for
                                  the administration and use of your account, to
                                  respond to service requests, or for other
                                  non-marketing purposes. To otherwise opt-out,
                                  you may:
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: "15px" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="forloop-component" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <ul>
                            <li style={{ lineHeight: "1.5" }}>
                              <bdt className="question">
                                <span data-custom-class="body_text">
                                  Contact us using the contact information
                                  provided.
                                </span>
                              </bdt>
                            </li>
                          </ul>
                          <div>
                            <bdt className="forloop-component">
                              <span data-custom-class="body_text">
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: "15px" }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span style={{ fontSize: "15px" }}>
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </bdt>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div id="DNT" style={{ lineHeight: "1.5" }}>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: "15px",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span
                                      id="control"
                                      style={{ color: "rgb(0, 0, 0)" }}>
                                      <strong>
                                        <span data-custom-class="heading_1">
                                          11. CONTROLS FOR DO-NOT-TRACK FEATURES
                                        </span>
                                      </strong>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span data-custom-class="body_text">
                                  Most web browsers and some mobile operating
                                  systems and mobile applications include a
                                  Do-Not-Track ("DNT") feature or setting you
                                  can activate to signal your privacy preference
                                  not to have data about your online browsing
                                  activities monitored and collected. At this
                                  stage no uniform technology standard for
                                  recognizing and implementing DNT signals has
                                  been finalized. As such, we do not currently
                                  respond to DNT browser signals or any other
                                  mechanism that automatically communicates your
                                  choice not to be tracked online. If a standard
                                  for online tracking is adopted that we must
                                  follow in the future, we will inform you about
                                  that practice in a revised version of this
                                  privacy notice.
                                </span>
                              </span>
                              &nbsp;
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div id="caresidents" style={{ lineHeight: "1.5" }}>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: "15px",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span
                                      id="control"
                                      style={{ color: "rgb(0, 0, 0)" }}>
                                      <strong>
                                        <span data-custom-class="heading_1">
                                          12. DO CALIFORNIA RESIDENTS HAVE
                                          SPECIFIC PRIVACY RIGHTS?
                                        </span>
                                      </strong>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span data-custom-class="body_text">
                                  <strong>
                                    <em>In Short:&nbsp;</em>
                                  </strong>
                                  <em>
                                    &nbsp;Yes, if you are a resident of
                                    California, you are granted specific rights
                                    regarding access to your personal
                                    information.
                                  </em>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span data-custom-class="body_text">
                                  California Civil Code Section 1798.83, also
                                  known as the "Shine The Light" law, permits
                                  our users who are California residents to
                                  request and obtain from us, once a year and
                                  free of charge, information about categories
                                  of personal information (if any) we disclosed
                                  to third parties for direct marketing purposes
                                  and the names and addresses of all third
                                  parties with which we shared personal
                                  information in the immediately preceding
                                  calendar year. If you are a California
                                  resident and would like to make such a
                                  request, please submit your request in writing
                                  to us using the contact information provided
                                  below.
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span data-custom-class="body_text">
                                  If you are under 18 years of age, reside in
                                  California, and have a registered account with{" "}
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: "15px",
                                        }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                                the Website
                                                <bdt className="statement-end-if-in-editor" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  , you have the right to request removal of
                                  unwanted data that you publicly post on the{" "}
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: "15px",
                                        }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                                Website
                                                <bdt className="statement-end-if-in-editor" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  . To request removal of such data, please
                                  contact us using the contact information
                                  provided below, and include the email address
                                  associated with your account and a statement
                                  that you reside in California. We will make
                                  sure the data is not publicly displayed on the{" "}
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}>
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: "15px",
                                        }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                                Website
                                                <bdt className="statement-end-if-in-editor" />
                                                <bdt className="block-component" />
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                  , but please be aware that the data may not be
                                  completely or comprehensively removed from all
                                  our systems (e.g. backups, etc.).
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div id="policyupdates" style={{ lineHeight: "1.5" }}>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: "15px",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span
                                      id="control"
                                      style={{ color: "rgb(0, 0, 0)" }}>
                                      <strong>
                                        <span data-custom-class="heading_1">
                                          13. DO WE MAKE UPDATES TO THIS NOTICE?
                                        </span>
                                      </strong>
                                      &nbsp;
                                    </span>
                                    &nbsp;
                                  </span>
                                  &nbsp;
                                </span>
                                &nbsp;
                              </span>
                              &nbsp;
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <em>
                              <br />
                            </em>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span data-custom-class="body_text">
                                  <em>
                                    <strong>In Short:&nbsp;</strong> Yes, we
                                    will update this notice as necessary to stay
                                    compliant with relevant laws.
                                  </em>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span data-custom-class="body_text">
                                  We may update this privacy notice from time to
                                  time. The updated version will be indicated by
                                  an updated "Revised" date and the updated
                                  version will be effective as soon as it is
                                  accessible. If we make material changes to
                                  this privacy notice, we may notify you either
                                  by prominently posting a notice of such
                                  changes or by directly sending you a
                                  notification. We encourage you to review this
                                  privacy notice frequently to be informed of
                                  how we are protecting your information.
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div id="contact" style={{ lineHeight: "1.5" }}>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                              <span
                                style={{
                                  color: "rgb(89, 89, 89)",
                                  fontSize: "15px",
                                }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span
                                      id="control"
                                      style={{ color: "rgb(0, 0, 0)" }}>
                                      <strong>
                                        <span data-custom-class="heading_1">
                                          14. HOW CAN YOU CONTACT US ABOUT THIS
                                          NOTICE?
                                        </span>
                                      </strong>
                                      &nbsp;
                                    </span>
                                    &nbsp;
                                  </span>
                                  &nbsp;
                                </span>
                                &nbsp;
                              </span>
                              &nbsp;
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span data-custom-class="body_text">
                                  If you have questions or comments about this
                                  notice, you may{" "}
                                  <span
                                    style={{
                                      color: "rgb(89, 89, 89)",
                                      fontSize: "15px",
                                    }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                      email us at{" "}
                                      <bdt className="question">
                                        francois@vautours.net
                                      </bdt>
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}>
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        color: "rgb(89, 89, 89)",
                                      }}>
                                      <span data-custom-class="body_text">
                                        &nbsp;or by post to:
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <br />
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                          <bdt className="question">
                                            Seo-Metrics.Net
                                          </bdt>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                                <span data-custom-class="body_text">
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: "15px" }}>
                              <span data-custom-class="body_text">
                                <bdt className="question">__________</bdt>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: "15px" }}>
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: "15px" }}>
                              <span data-custom-class="body_text">
                                <bdt className="question">__________</bdt>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ fontSize: "15px" }}>
                                    <bdt className="block-component" />
                                    <bdt className="block-component" />
                                  </span>
                                  <span style={{ fontSize: "15px" }}>
                                    <bdt className="block-component" />
                                    <bdt className="block-component" />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: "15px" }}>
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: "15px" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt className="block-component" />
                                    </span>
                                  </span>
                                </span>
                                <bdt className="question">Belgium</bdt>
                                <span style={{ fontSize: "15px" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <bdt className="statement-end-if-in-editor">
                                        <span style={{ fontSize: "15px" }}>
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}>
                                              <bdt className="statement-end-if-in-editor">
                                                <span
                                                  style={{ fontSize: "15px" }}>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}>
                                                      <bdt className="statement-end-if-in-editor" />
                                                    </span>
                                                  </span>
                                                </span>
                                              </bdt>
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                        </span>
                                        <span data-custom-class="body_text">
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}>
                                            <span style={{ fontSize: "15px" }}>
                                              <bdt className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                        </span>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}>
                                          <span style={{ fontSize: "15px" }}>
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component">
                                                <bdt className="block-component">
                                                  <bdt className="block-component" />
                                                </bdt>
                                              </bdt>
                                            </span>
                                          </span>
                                        </span>
                                      </bdt>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </div>
                          <style dangerouslySetInnerHTML={{ __html: "\n" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //get_smtg: () => dispatch({ type: "GET_smtg" }),
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PrivacyPolicy));
