import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import BottomPricing from "./../containers/_svg/_bottomPricing.js";
//setInterval(function () {
//let svgs = Array.from(document.getElementsByClassName("shape-svg"));
//svgs.forEach(el => el.classList.toggle("second-form"));
//}, 4000);

class AboutUs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { t } = this.props;
    return (
      <div id="AboutUs">
        <BottomPricing className="BottomPricing" />
        <div id="AboutUs-body">
          <h1>{t("au-title")}</h1>
          <h2>{t("au-who-title")}</h2>
          <div>{t("au-who-body")}</div>
          <h2>{t("au-why-title")}</h2>
          <div>{t("au-why-body")}</div>
          <a href="https://app.vautours.net" className="btn">
            {t("uc-gs")}
          </a>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //get_smtg: () => dispatch({ type: "GET_smtg" }),
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AboutUs));
