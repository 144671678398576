import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={340.035}
      height={401.107}
      viewBox="0 0 340.035 401.107"
      {...props}
    >
      <g data-name="Group 118">
        <path
          className="smallGoo"
          data-name="Path 65"
          d="M241.001 214.432C144.1 108.369 132.315 41.581 95.435 35.556c-25.668-4.193-63.228 32.5-82.745 82.6C4.246 139.833-.801 164.185.105 188.767c2.979 80.86 55.751 170.232 107.246 170.232s230.551-38.499 133.65-144.567z"
          fill="rgba(24,144,255,0.2)"
        />
        <path
          data-name="Path 66"
          className="largeGoo"
          d="M293.693 203.048c3.419-43.819-7.038-101.34-44.701-137.625s-113.048-45.69-105.954-7.518c7.645 41.18 147.237 188.962 150.655 145.143zM219.076 381.727c26.105 2.035 60.37-4.193 81.987-26.63s27.218-67.345 4.477-63.12c-24.532 4.556-112.569 87.713-86.464 89.75z"
          fill="#ff9a9a"
          opacity={0.34}
        />
      </g>
    </svg>
  )
}

export default SvgComponent

