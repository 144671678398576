import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
//import i18n from "./../my-i18next.js";
import { useTranslation } from "react-i18next";
import GSSVG from "./_svg/_GSSVG.js";
import GridSvg from "./_svg/_grid.js";

SwiperCore.use([Pagination, Navigation]);

function GetStarted() {
  const { t, i18n } = useTranslation();
  const [current_slide, set_current_slide] = useState(0);

  let slides = [
    {
      key: "login",
      title: t("gs-1-title"),
      desc: t("gs-1-desc"),
      cta: `${t("gs-1-cta")} 🎉`,
      img: "/step1Signupv2.png",
    },
    {
      key: "logon",
      title: t("gs-2-title"),
      desc: t("gs-2-desc"),
      cta: `${t("gs-2-cta")}`,
      img: "/step2Create.png",
    },
    {
      key: "monitor",
      title: t("gs-3-title"),
      desc: t("gs-3-desc"),
      cta: `${t("gs-3-cta")}`,
      img: "/step3Monitor.png",
    },
  ];
  let cslide = slides[current_slide] ? slides[current_slide] : slides[0];
  return (
    <div id="GetStarted">
      <GridSvg className="grid-svg-get-started" />
      <div className="gs-title">{t("gs-title")}</div>
      <div className="gs-central">
        <div className="gs-text">
          <div className="gs-text-title">{cslide.title}</div>
          <div className="gs-text-desc">{cslide.desc}</div>
          <div className="gs-text-cta">
            {" "}
            <button>
              {" "}
              <a href="https://app.vautours.net">{cslide.cta}</a>
            </button>
          </div>
          <div className="slide-control">
            <div className="arrow-box prev">&#8592; </div>
            <div className="slide-number">
              {current_slide + 1}/ {slides.length}
            </div>
            <div className="arrow-box next">&#8594; </div>
          </div>
        </div>

        <Swiper
          className="swiper"
          spaceBetween={15}
          slidesPerView={1.15}
          navigation={{
            prevEl: ".prev",
            nextEl: ".next",
          }}
          grabCursor
          onSlideChange={(swiper) => set_current_slide(swiper.activeIndex)}>
          {slides.map((s) => (
            <SwiperSlide key={s.key}>
              <div className={`slide slide${s.key}`}>
                <img src={`${s.img}`} alt={`${s.desc}`} />
              </div>
            </SwiperSlide>
          ))}
          <SwiperSlide key="Last Slide">
            <div className="slide">
              <video
                className="slide video-home-out"
                controls
                src="https://s3.eu-central-1.amazonaws.com/staging.vautours.net/MotionMintox-Vautours+01-08.mp4"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <GSSVG className={`gs-svg gs-svg-${current_slide}`} />
    </div>
  );
}

export default GetStarted;
