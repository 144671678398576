import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={368.81}
      height={345.835}
      viewBox="0 0 368.81 345.835"
      {...props}
    >
      <path
        data-name="Path 3"
        className="largeGoo"
        d="M271.198 303.47c92.89-11.9 67.357-231.613 17.764-252.11s-118.346-29.917-169.331.499S39.357 166.944 85.026 173.02c49.264 6.55 93.283 142.348 186.172 130.45z"
        fill="#0767c0"
        opacity={0.14}
      />
      <path
        data-name="Path 53"
        className="smallGoo"
        d="M110.982 297.488c26.727-3.317 18.909-68.256 4.583-74.413s-34.146-9.085-48.766-.21-29.51 48.535-18.464 67.19 45.674 9.54 62.647 7.433z"
        fill="#ff9a9a"
        opacity={0.41}
      />
    </svg>
  )
}

export default SvgComponent
