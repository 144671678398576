import * as React from "react";

function UCSvg1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={709.539}
      height={496.024}
      viewBox="0 0 709.539 496.024"
      {...props}>
      <path
        data-name="Path 56"
        d="M27.376 199.453C102.954 88.986 340.264-23.806 426.027 4.382s90.051 277.311-64.322 420.537-187.282 27.255-249.423 18.511-160.484-133.51-84.906-243.977z"
        fill="#498cca"
        opacity={0.31}
      />
      <path
        data-name="Path 57"
        d="M707.976 282.561c24.214-85.253-239.112-206.323-351.1-161.426S172.245 421.791 260.02 462.148s423.742-94.329 447.956-179.587z"
        fill="#ff9a9a"
      />
    </svg>
  );
}

function UCSvg2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={604.024}
      height={702.75}
      viewBox="0 0 604.024 702.75"
      {...props}>
      <path
        data-name="Path 58"
        d="M64.104 199.453C139.682 88.986 376.992-23.806 462.755 4.382s90.051 277.311-64.322 420.537S-11.474 309.92 64.104 199.453z"
        fill="#498cca"
      />
      <path
        data-name="Path 59"
        d="M511.895 543.168c65.717-59.46-93.416-301.691-212.177-322.972S-16.197 377.298 36.847 458.041s409.328 144.593 475.048 85.127z"
        fill="#ff9a9a"
      />
    </svg>
  );
}

function UCSvg3(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={905.654}
      height={663.062}
      viewBox="0 0 905.654 663.062"
      {...props}>
      <path
        data-name="Path 60"
        d="M400.292 473.796C368.77 343.714 443.74 91.886 522.075 47.013s266.339 118.636 269.48 329.195-359.742 227.67-391.263 97.588z"
        fill="#498cca"
      />
      <path
        data-name="Path 61"
        d="M86.906 245.973C23.31 307.697 190.822 544.21 310.255 561.323S620.478 393.261 564.64 314.423s-414.136-130.18-477.735-68.451z"
        fill="#ff9a9a"
      />
    </svg>
  );
}
export { UCSvg1, UCSvg2, UCSvg3 };
