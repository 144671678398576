import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={44} height={23} viewBox="0 0 44 23" {...props}>
      <g
        data-name="Group 26"
        transform="translate(-2626.018 -114)"
        opacity={0.74}
        fill="#265e98">
        <rect
          data-name="Rectangle 6"
          width={props.menuOpen ? 44 : 44}
          height={5}
          rx={2.5}
          transform="translate(2626.018 114)"
        />
        <rect
          data-name="Rectangle 7"
          width={props.menuOpen ? 44 : 27}
          height={5}
          rx={2.5}
          transform={`translate(${props.menuOpen ? "2626.018" : "2643.018"} 123)`}
        />
        <rect
          data-name="Rectangle 8"
          width={props.menuOpen ? 44 : 14}
          height={5}
          rx={2.5}
          transform={`translate(${props.menuOpen ? "2626.018" : "2655.018"} 132)`}
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
