import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={1920.169}
      height={177.788}
      viewBox="0 0 1920.169 177.788"
      {...props}
    >
      <path
        data-name="Path 1"
        d="M1920.168 47.351s-245.406 25.465-524.006 18.961-410.122-16.607-590.4-44.978S333-1.74 190.582 6.803 0 30.26 0 30.26v147.528h1920.168z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgComponent

