const user = {
  nickname: "SeoMetrics",
  name: "watcher",
  picture: "https://staging.vautours.net/favicon.ico",
  updated_at: "2020-01-14T22:30:50.938Z",
  email: "watcher@vautours.net",
  email_verified: true
};

const user_reducer = (state = user, action) => {
  switch (action.type) {
    case "DETAIL_USER_SUCCESS":
      let user = action.payload.data;
      let newrState = Object.assign({}, state, {
        ...user
      });
      return newrState;

    default:
      return state;
  }
};

export default user_reducer;
