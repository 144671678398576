import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
//import i18n from "./../my-i18next.js";
import { useTranslation } from "react-i18next";
import { UCSvg1, UCSvg2, UCSvg3 } from "./_svg/_usecasecard.js";

SwiperCore.use([Pagination, Navigation]);

function UseCases() {
  const { t, i18n } = useTranslation();
  const [current_slide, set_current_slide] = useState(0);

  return (
    <div id="UseCases">
      <div className="uc-title">{t("uc-title")}</div>
      <Swiper
        className="swiper ucs"
        breakpoints={{
          720: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
          1200: {
            slidesPerView: 1.5,
            spaceBetween: 50,
          },
        }}
        navigation={{
          prevEl: ".prev",
          nextEl: ".next",
        }}
        grabCursor
        onSlideChange={(swiper) => set_current_slide(swiper.activeIndex)}>
        {[1, 2, 3].map((n) => (
          <SwiperSlide key={`uc-key-${n}`}>
            <div className={`uc uc-${n}`}>
              {n === 1 && <UCSvg1 className="uc-svg" />}
              {n === 2 && <UCSvg2 className="uc-svg" />}
              {n === 3 && <UCSvg3 className="uc-svg" />}
              <div className="card-title">{t(`uc-name-${n}`)}</div>
              <div
                className="card-body"
                dangerouslySetInnerHTML={{ __html: t(`uc-body-${n}`)}}></div>
              <Link to={`/use-case/${n}`} className="card-cta">
                {t(`uc-cta`)}
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default UseCases;
