import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import BottomPricing from "./../containers/_svg/_bottomPricing.js";
//setInterval(function () {
//let svgs = Array.from(document.getElementsByClassName("shape-svg"));
//svgs.forEach(el => el.classList.toggle("second-form"));
//}, 4000);

class DataDeletion extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { t } = this.props;
    return (
      <div id="DataDeletion">
        <div style={{padding : "100px", paddingTop:"300px"}}id="DataDeletion-body">
      If you would at any time like to review or change the information in your account or terminate your account, you can:
      <br />
      <br />
      
Contact us using the contact information provided.
Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.
      <hr />
      to contact us, you can send a mail to <a href='mailto:francois+data-deletion@vautours.com'>francois@vautours.net</a>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //get_smtg: () => dispatch({ type: "GET_smtg" }),
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DataDeletion));
