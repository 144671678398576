import * as React from "react";

let colors = [
  //$lightBlue: #1890ff;
  "#1890ff",
  //$lightRed: #ff9a9a;
  "#ff9a9a",
  //$lightPurple: #9470ab;
  "#9470ab",
  //$mediumBlue: #0767c0;
  "#0767c0",
  //$darkBlue: #265e98;
  "#265e98",
  //$darkGrey: #707070;
  "#707070"
];

function Svg1(props) {
  return (
    <svg viewBox="0 0 200 200" {...props}>
      <svg viewBox="0 0 200 200" {...props}>
        <path
          className="inside-shape"
          fill={`${colors[Math.floor(Math.random() * colors.length)]}`}
          d="M150.8 31.2c13.2 8.4 20.7 25.8 24 42.8 3.2 17.1 2.3 33.9-3.3 48.6-5.6 14.7-15.8 27.4-28.5 37.3-12.7 9.8-27.8 17-42.1 15.7-14.2-1.2-27.5-10.9-40.6-20.7-13.1-9.8-25.9-19.6-33.4-33.3-7.4-13.8-9.5-31.4-3-44.8 6.4-13.4 21.4-22.4 35.5-30.5 14.1-8.1 27.4-15.2 43.4-19.1 16-3.8 34.9-4.4 48 4z"
        />
      </svg>
    </svg>
  );
}

function Svg2(props) {
  return (
    <svg viewBox="0 0 200 200" {...props}>
      <path
        fill={`${colors[Math.floor(Math.random() * colors.length)]}`}
        d="M140.1 87.7c4.7 13.9-3.4 32.8-21.9 49-18.5 16.2-47.4 29.7-61.9 20-14.6-9.7-14.7-42.7-5-63.4 9.6-20.7 29.2-29 47.8-28.7 18.6.3 36.2 9.2 41 23.1z"
      />
    </svg>
  );
}

function Svg3(props) {
  return (
    <svg viewBox="0 0 200 200" {...props}>
      <path
        fill={`${colors[Math.floor(Math.random() * colors.length)]}`}
        d="M147.4 44.7c16 12.2 32.2 25.3 34 40.2 1.8 15-10.9 31.8-23 45-12.2 13.3-23.9 23-37.4 28.1-13.6 5.1-28.9 5.5-44.9 2.1s-32.6-10.6-41-23.5c-8.5-12.8-8.7-31.2-4.8-48.1 4-16.9 12.1-32.2 24.4-45.1C67 30.6 83.5 20.1 99.6 20.6c16.1.5 31.8 11.8 47.8 24.1z"
      />
    </svg>
  );
}

export { Svg1, Svg2, Svg3 };
