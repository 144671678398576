import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { index } from "./translations/index.js";
import { usecases } from "./translations/usecases.js";
import { aboutus } from "./translations/aboutus.js";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          ...index.en,
          ...usecases.en,
          ...aboutus.en,
        }
      },
      fr: {
        translations: {
          ...index.fr,
          ...usecases.fr,
          ...aboutus.fr,
        }
      }
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
