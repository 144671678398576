export const index = {
  en: {
    "sign-in": "Sign In",
    "sign-out": "Sign Out",
    "go-to-vautours": "My App",
    home: "Home",
    pricing: "Pricing",
    features: "Features",
    "contact-us": "Contact Us",
    contact: "Contact Us",
    about: "About Us",
    whyvautours: "Why Seo-Metrics.Net",
    "prev-vautours" :"Previously Vautours.Net",
    "header-sub-title":
      "A SaaS to Monitor your website SEO, Health, and Performance in 3 Clicks",
    "header-cta-gs": "Get Started",
    "header-cta-lm": "Learn More",
    "header-no-credit-card": "No Credit Card Required",
    "hf-why-you-should": "Why You Should Use Seo-Metrics.Net",
    "hf-alert-title": "Be Alerted At The Right Time",
    "hf-alert-desc":
      "Be The First To Know When Something Goes Wrong. Be Alerted By Mail, Slack And Even Text Message*",
    "hf-seo-title": "Track Your SEO Progress",
    "hf-seo-desc":
      "Get A Ranking Of Different Parameters Impacting Your Search Engine Score. Get Info About Your Accessibility, Performances And Even Specific Tips About SEO",
    "hf-ts-title": "Start Trouble shooting",
    "hf-ts-desc":
      "You Know Something Is Wrong, Now What ? Go to Seo-Metrics.Net and find what the problem was.",
    "hf-help-title": "Quickly Get Help",
    "hf-help-desc":
      "Can't Find The Solution On Your Own? Find an expert and Get Immediate Help at a fair price(*beta)",
    "gs-title": "Get Started",
    "gs-1-title": "Create Your Account in 1min",
    "gs-1-desc":
      "first you just have to sign-up either with your social provider or by mail. It is free and there is no credit card required. ",
    "gs-1-cta": "Go to Seo-Metrics.Net",
    "gs-2-title": "Create Your First Checker",
    "gs-2-desc":
      "To start monitoring your website fill fill-in the form. If you have any doubt, hover over the (?) to get extra tips !",
    "gs-2-cta": "Create Your First Checker",
    "gs-3-title": "See your Checker",
    "gs-3-desc":
      "Once this is done go see your checker. Your SEO data will appear in a minute !",
    "gs-3-cta": "Get Started",
    "ft-title": "Why Seo-Metrics.Net ?!",
    "ft-alert-title": "Alert monitoring at the right moment ",
    "ft-alert-desc":
      "SSL, Keyword and HTTP status monitoring. Make sure that if anything goes wrong you will be the first to know it!",
    "ft-seo-title": "A way to track your progresses",
    "ft-seo-desc":
      "Performance, Accessibility, Best-practices and SEO checklist Thanks to our lighthouse intégration we can help you get an report every day ",
    "ft-ts-title": "Tools to help you troubleshoot",
    "ft-ts-desc":
      "See the page we scraped, the HTTP code and get the moment the check failed",
    "pricing-basic-title": "Basic",
    "pricing-basic-label": "It Is Free Monitoring",
    "pricing-basic-cta": "Start For Free",
    "pricing-pro-title": "Pro",
    "pricing-pro-cta": "Go Pro",
    "pricing-pro-label": "Most Used",
    "pricing-proy-title": "Pro",
    "pricing-proy-cta": "Go Pro For Less",
    "pricing-proy-sub-title": "Yearly",
    "pricing-proy-label": "The Best-Value",
    "pricing-cem": "Check Every Minute",
    "pricing-alert-mail": "Mail Intégration",
    "pricing-alert-slack": "Slack Integration",
    "pricing-alert-sms": "Up to 30 Text Messages",
    "pricing-seo-monitoring": "SEO Monitoring",
    "pricing-bp-monitoring": "Best-Practices Monitoring",
    "pricing-perf-monitoring": "Performance Monitoring",
    "pricing-acce-monitoring": "Accessibility Monitoring",
    "pricing-ssl-monitoring": "HTTPS status Monitoring",
    "pricing-ts-file": "Failure Report",
  },
  fr: {
    "sign-in": "Connexion",
    "sign-out": "Déconnexion",
    "go-to-vautours": "Mon App",
    home: "Accueil",
    pricing: "Pricing",
    features: "Services",
    "contact-us": "Contact",
    contact: "Contact",
    about: "À propos",
    whyvautours: "Pourquoi Seo-Metrics.Net",
    "prev-vautours" :"Précédemment Vautours.Net",
    "header-sub-title":
      "Un SaaS pour Surveiller la santé de votre site, son SEO, sa Performance en 3 clics",
    "header-cta-gs": "Commencer 🔥",
    "header-cta-lm": "Voir Plus",
    "header-no-credit-card": "Pas de carte de crédit nécessaire",
    "hf-why-you-should": "Pourquoi utiliser Seo-Metrics.Net",
    "hf-alert-title": "Soyez averti au bon moment",
    "hf-alert-desc":
      "Soyez le premier à savoir quand quelque chose ne va pas. Recevez une alerte par Mail, Slack et même SMS*",
    "hf-seo-title": "Suivez votre référencement SEO",
    "hf-seo-desc":
      "Obtenez votre score SEO, mais aussi des informations sur la Performance, l'Accessibilité et même des conseils spécifiques sur le référencement",
    "hf-ts-title": "Troubleshooting",
    "hf-ts-desc":
      "Vous savez quelque chose ne va pas et maintenant? On vous aide à trouvez le problème téléchargeant l'HTML que nous avons reçu.",
    "hf-help-title": "Rapidement obtenir de l'aide",
    "hf-help-desc":
      "Impossible de trouver la solution à votre problème? Trouvez un expert qualifé sur notre Marketplace (* bientôt)",
    "gs-title": "Commencer Maintenant",
    "gs-1-title": "Créez votre compte en 1min",
    "gs-1-desc":
      "Pour commencer, il vous suffit de vous inscrire à l'aide de votre réseau social préféré ou par Mail. C'est gratuit et vous n'avez pas besoin de carte de crédit.",
    "gs-1-cta": "Aller sur Seo-Metrics.Net",
    "gs-2-title": "Créez votre premier Checker",
    "gs-2-desc":
      "Remplissez le formulaire en quelques minutes. Si vous avez une question survolez le (?) Pour obtenir des conseils supplémentaires!",
    "gs-2-cta": "Créez votre premier Checker",
    "gs-3-title": "Consultez votre Checker",
    "gs-3-desc":
      "Une fois cela fait allez voir votre Checker. Vos données SEO apparaîtront dans une minute!",
    "gs-3-cta": "Commencer",
    "ft-title": "Pourquoi Seo-Metrics.Net?!",
    "ft-alert-title": "Recevez une alert au bon moment",
    "ft-alert-desc":
      "Nous surveillons vos certificats SSL et nous assurons de trouver un de vos mots-clés dans l'HTML. Si quelque chose ne va pas, vous serez le premier à le savoir.",
    "ft-seo-title": "Un moyen de suivre vos progrès",
    "ft-seo-desc":
      "Grâce à notre intégration LightHouse, nous vous offrons un récapitulatif de l'Accessibilité, les Performances et le suivi des meilleures pratiques pour améliorer le SEO de votre site.",
    "ft-ts-title": "Des outils pour vous aider à régler le problème",
    "ft-ts-desc":
      "En cas de problème nous téléchargeons la page de votre site pour que vous puissiez voir quel était le problème. Nous vous indiquons aussi le moment où votre site a rencontré un problème.",
    "pricing-basic-title": "Basic",
    "pricing-basic-label": "It Is Free Monitoring",
    "pricing-basic-cta": "Démarrer Gratuitement",
    "pricing-pro-title": "Pro",
    "pricing-pro-cta": "Go Pro",
    "pricing-pro-label": "Le Plus Utilisé",
    "pricing-proy-title": "Pro",
    "pricing-proy-cta": "Go Pro pour moins",
    "pricing-proy-sub-title": "Annuel",
    "pricing-proy-label": "Meilleur Rapport Qualité",
    "pricing-cem": "Vérification chaque minute",
    "pricing-alert-mail": "Alerte par Mail",
    "pricing-alert-slack": "Intégration Slack",
    "pricing-alert-sms": "Jusqu'à 30 SMS",
    "pricing-seo-monitoring": "Suivi SEO",
    "pricing-bp-monitoring": "Suivi des Best-Practices",
    "pricing-perf-monitoring": "Suivi de Performances",
    "pricing-acce-monitoring": "Suivi de l'Accessibilité",
    "pricing-ssl-monitoring": "Surveillance SSL",
    "pricing-ts-file": "Rapport d'erreur",
  },
};
