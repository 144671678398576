import { put } from "redux-saga/effects";
import API from "./../../ApiLayer/index.js";
// worker Saga: will be fired on USER_FETCH_REQUESTED actions

export function* detailUser() {
  yield put({ type: "DETAILING_USER" });
  try {
    const user = yield API.detailUser();
    yield put({ type: "DETAIL_USER_SUCCESS", payload: user });
    if (user.data.should_update) {
      console.log(user.data.should_update)
      yield put({ type: "GET_CHECKER" });
    }
  } catch (e) {
    yield put({ type: "DETAIL_USER_FAILED", payload: e });
  }
}
