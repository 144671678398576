export const aboutus = {
  en: {
    "au-title": "About Us",
    "au-who-title": "Who Are We?",
    "au-who-body":
      "We are a small company that develops web solutions for individuals but also for medium sized companies. We love creating web products, but we know how annoying it can be to maintain all that. That is why we created Seo-Metrics.Net.",
    "au-why-title": "Why did we make Seo-Metrics.Net? ",
    "au-why-body":
      "During some discussions with our clients we realized that in addition to a simple monitoring solution, many were interested in a SaaS solution that would put at their fingertips the various information that could help them move up the search engine rankings.",
  },
  fr: {
    "au-title": "À Propos de Nous",
    "au-who-title": "Qui Sommes Nous?",
    "au-who-body":
      "Nous sommes une petite entreprise qui développe de solutions web pour les particuliers mais aussi pour les entreprises de taille moyenne. Nous aimons la création de produit web mais nous savons à quel point il peut être embêtant de maintenir tout ça. C'est pourquoi nous avons créé Seo-Metrics.Net.",
    "au-why-title": "Pourquoi avons-nous fait Seo-Metrics.Net ? ",
    "au-why-body": "Au fur et à mesure des discussions avec nos clients nous nous sommes rendu compte qu'en plus d'une simple solution de monitoring, beaucoup étaient intéressé par un SaaS leur mettant sous la main les différentes informations qui pouvaient leur permettre de monter plus haut dans les recherches des moteurs de recherche.",
  },
};
