class N {
  constructor() {
    this.worthy_codes = [
      "this_code",
    ];
  }
  evaluate(json) {
    if (json.success_code !== undefined) {
      this.notify_success(json);
    }
    if (json.error_code !== undefined) {
      this.notify_error(json);
    }
    return;
  }

  notify_success(json) {
    console.log(json)
  }

  notify_error(json) {
    console.log(json)
  }
}

const Notification = new N();
export default Notification;
