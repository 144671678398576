import React from "react";
import { useTranslation } from "react-i18next";
import GridSvg from "./_svg/_grid.js";
import { Svg1, Svg2 } from "./_svg/_random_svg.js";

function Features() {
  const { t } = useTranslation();
  return (
    <div id="Features">
      <div className="ft-title">{t("ft-title")}</div>
      <div className="ft-section slack">
        <div className="ft-img-box">
          <Svg1 className="shape-svg svg-1-features" />
          <video
            poster="/poster-video-vautours.png"
            className="slide video-home-out"
            type="video/mp4"
            controls
            src="https://s3.eu-central-1.amazonaws.com/staging.vautours.net/MotionMintox-Vautours+01-08.mp4"
          />
          <img
            className="support-img slack-app"
            src="/slack-app.png"
            alt="slack-integration"
          />
          <img
            id="this"
            className="support-img slack-notification"
            src="/slack-notification.png"
            alt="slack-notification"
          />
          <Svg2 className="shape-svg svg-2-features" />
        </div>
        <div className="ft-text">
          <GridSvg className="grid-svg-features-alert" />
          <div className="ft-text-title">{t("ft-alert-title")} 🔥</div>
          <div className="ft-text-desc">{t("ft-alert-desc")}</div>
        </div>
      </div>
      <div className="ft-section">
        <div className="ft-text">
          <div className="ft-text-title">{t("ft-seo-title")} 🔍</div>
          <div className="ft-text-desc">{t("ft-seo-desc")}</div>
        </div>
        <div className="ft-img-box">
          <Svg1 className="shape-svg svg-1-features" />
          <img
            className="img-main slack-app"
            src="/seo-report-main.png"
            alt="calculate the seo score"
          />
          <img
            className="support-img seo-back-img"
            src="/seo-report-lh.png"
            alt="slack-integration"
          />
          <img
            id="this"
            className="support-img seo-badge"
            src="/seo-score-no-avg.png"
            alt="slack-notification"
          />
          <Svg2 className="shape-svg svg-2-features" />
        </div>
      </div>
      <div className="ft-section">
        <div className="ft-img-box">
          <Svg1 className="shape-svg svg-1-features" />
          <img
            className="img-main troubleshooting-main"
            src="/troubleshooting-main.png"
            alt="calculate the seo score"
          />
          <img
            className="support-img troubleshooting-badge"
            src="/troubleshooting-badge.png"
            alt="Vautours.net download the page and help you troubleshooting"
          />
          <img
            className="support-img troubleshooting-back"
            src="/troubleshooting-back.png"
            alt="troubleshooting"
          />
          <Svg2 className="shape-svg svg-2-features" />
        </div>
        <div className="ft-text">
          <div className="ft-text-title">{t("ft-ts-title")} 🧰</div>
          <div className="ft-text-desc">{t("ft-ts-desc")}</div>
        </div>
      </div>
    </div>
  );
}

export default Features;
