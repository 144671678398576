import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import i18n from "./../my-i18next.js";
import { withTranslation } from "react-i18next";
import { Price1, Price2, Price3 } from "./_svg/_priceSvg.js";
import BottomPricing from "./_svg/_bottomPricing.js";

class Pricing extends Component {
  render() {
    const { t } = this.props;
    return (
      <div id="Pricing">
        <BottomPricing className="BottomPricing" />
        <div className="price-central">
          <div className="price-card">
            <div className="price-title">
              {t("pricing-basic-title")}
              <div className="price-tag">
                <div className="price-tag-inside">
                  <div className="price-tag-price">0</div>
                  <Price1 />
                </div>
              </div>
            </div>
            <div className="price-funct">
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-cem")}
              </div>

              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-alert-slack")}
              </div>

              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-seo-monitoring")}
              </div>
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-bp-monitoring")}
              </div>
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-acce-monitoring")}
              </div>
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-perf-monitoring")}
              </div>
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-ts-file")}
              </div>
              <div className="funct nok">
                <img src="/nokCheckMark.png" alt="not found" />
                {t("pricing-ssl-monitoring")}
              </div>
              <div className="funct nok">
                <img src="/nokCheckMark.png" alt="not found" />
                {t("pricing-alert-sms")}
              </div>
            </div>
            <div className="price-cta">
              <a href="https://app.vautours.net/?basic">
                {t("pricing-basic-cta")}
              </a>
            </div>
            <div className="price-label">{t("pricing-basic-label")} 💸</div>
          </div>
          <div className="price-card central">
            <div className="price-title">
              Pro
              <div className="price-tag">
                <div className="price-tag-inside">
                  <div className="price-tag-price">
                    14<span>.99</span>
                  </div>
                  <Price2 />
                </div>
              </div>
            </div>
            <div className="price-funct">
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-cem")}
              </div>

              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-alert-slack")}
              </div>

              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-seo-monitoring")}
              </div>
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-bp-monitoring")}
              </div>
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-acce-monitoring")}
              </div>
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-perf-monitoring")}
              </div>
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-ts-file")}
              </div>
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-ssl-monitoring")}
              </div>
              <div className="funct ">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-alert-sms")}
              </div>
            </div>
            <div className="price-cta">
              <a href="https://app.vautours.net/?pro">
                {t("pricing-pro-cta")}{" "}
              </a>
            </div>
            <div className="price-label">{t("pricing-pro-label")}🎉</div>
          </div>
          <div className="price-card">
            <div className="price-title">
              Pro <div className="sub-title">{t("pricing-proy-sub-title")}</div>{" "}
              <div className="price-tag">
                <div className="price-tag-inside">
                  <div className="price-tag-price">
                    149<span>.9</span>
                  </div>
                  <Price3 />
                </div>
              </div>
            </div>
            <div className="price-funct">
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-cem")}
              </div>

              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-alert-slack")}
              </div>

              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-seo-monitoring")}
              </div>
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-bp-monitoring")}
              </div>
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-acce-monitoring")}
              </div>
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-perf-monitoring")}
              </div>
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-ts-file")}
              </div>
              <div className="funct">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-ssl-monitoring")}
              </div>
              <div className="funct ">
                <img src="/okCheckMark.png" alt="not found" />
                {t("pricing-alert-sms")}
              </div>
            </div>
            <div className="price-cta">
              <a href="https://app.vautours.net/?proy">
                {t("pricing-proy-cta")}
              </a>
            </div>
            <div className="price-label">{t("pricing-proy-label")} 🔥</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Pricing);
