import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import NavSvg from "./_svg/_navSvg.js";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      onTop: true,
    };
  }
  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("scroll", (w) => {
      let scrollY = window.scrollY;
      // less concise but not reloading the dom
      if (scrollY > 30 && this._isMounted) {
        this.setState({
          onTop: false,
        });
      }
      if (scrollY < 30 && this._isMounted) {
        this.setState({
          onTop: true,
        });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { t } = this.props;
    const decideWhereToGo = () => {
      if (this.props.isIn) {
        window.open("https://app.vautours.net");
      } else {
        this.props.signIn();
      }
    };
    return (
      <div id="nav">
        <div
          onClick={() => this.props.toggleNav()}
          className={`${this.props.menuOpen ? "show" : ""}`}
          id="m-toggle-nav">
          <NavSvg menuOpen={this.props.menuOpen} />
        </div>
        <div
          id="m-navigation-holder"
          className={`${this.props.menuOpen ? "show" : ""}`}>
          <div className="m-navigation-box">
            <div className="m-holder-nav">
              <div className="m-nav-up">
                <div className="nav-left-logo">
                  <img className="after-title" src="/favicon.png" alt="" />
                </div>
                <div className="nav-left-label">Vautours.net</div>
              </div>
              <div className="m-nav-down">
                <a onClick={() => this.props.toggleNav()} href="/#Features">
                  <div className="m-nav-right-sub">{t("features")}</div>
                </a>
                <a href="mailto:'jordanpasinc@outlook.com'">
                  <div className="m-nav-right-sub">{t("contact")} 👋</div>
                </a>
                <a onClick={() => this.props.toggleNav()} href="/#Pricing">
                  <div className="m-nav-right-sub">{t("pricing")}</div>
                </a>
                <div
                  onClick={() => decideWhereToGo()}
                  className="m-nav-right-sub sign-in">
                  {!this.props.isIn
                    ? `${t("sign-in")} 🔒`
                    : `${t("go-to-vautours")}`}
                </div>
                <div
                  onClick={() =>
                    this.props.changeLanguage(this.props.i18n.language)
                  }
                  className="m-nav-right-sub switch-language">
                  {this.props.i18n.language}{" "}
                  <span>
                    / {this.props.i18n.language === "en" ? "fr" : "en"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`desktop navigation-box ${
            this.state.onTop ? "onTop" : "notOnTop"
          }`}>
          <div className="holder-nav">
            <div id="nav" className="nav">
              <div
                onClick={() =>
                  this.props.changeLanguage(this.props.i18n.language)
                }
                className="switch-language">
                {this.props.i18n.language === "en" ? "fr" : "en"}
              </div>
              <Link to="/">
                <div className="nav-left">
                  <div className="nav-left-logo">
                    <img className="after-title" src="/favicon.png" alt="" />
                  </div>
                  <div className="nav-left-label">Seo-Metrics</div>
                </div>
              </Link>
              <div className="nav-right">
                <a href="/#Features">
                  <div className="nav-right-sub">{t("features")}</div>
                </a>
                <a href="mailto:'jordanpasinc@outlook.com'">
                  <div className="nav-right-sub">{t("contact")} 👋</div>
                </a>
                <a href="/#Pricing">
                  <div className="nav-right-sub">{t("pricing")}</div>
                </a>
                <div
                  onClick={() => decideWhereToGo()}
                  className="nav-right-sub sign-in">
                  {!this.props.isIn
                    ? `${t("sign-in")} 🔒`
                    : `${t("go-to-vautours")}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Navigation);
