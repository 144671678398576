import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import i18n from "./../my-i18next.js";
import { withTranslation } from "react-i18next";

class Footer extends Component {
  render() {
    const { t } = this.props;
    return (
      <div id="Footer">
        <div className="footer-central">
          <div className="col col-1">
            <a className="link" href="/">
              {t("home")}
            </a>
            <a className="link" href="/#Pricing">
              {t("pricing")}
            </a>
            <a className="link" href="/#Features">
              {t("features")}
            </a>
          </div>
          <div className="col col-2">
            <a className="link" href="/about-us">
              {t("about")}
            </a>
            <a className="link" href="/why-vautours">
              {t("whyvautours")}
            </a>
          </div>
          <div className="col col-3">
            <a className="link" href="mailto:'jordanpasinc@outlook.com'">
              {t("contact-us")}
            </a>
          </div>
        </div>
        <div className="credit">
          {" "}
          {new Date().getYear() + 1900} - Seo-Metrics.Net <br />
          <div className="flaticon">
            Icons made by{" "}
            <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
              Freepik
            </a>{" "}
            from{" "}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Footer);
