import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import BottomPricing from "./../containers/_svg/_bottomPricing.js";
//setInterval(function () {
//let svgs = Array.from(document.getElementsByClassName("shape-svg"));
//svgs.forEach(el => el.classList.toggle("second-form"));
//}, 4000);

class UseCasePage extends Component {
  componentDidMount() {
      window.scrollTo(0, 0)
    
  }
  render() {
    const { t } = this.props;
    let n = this.props.match.params.id;
    return (
      <div id="UseCasePage">
        <BottomPricing className="BottomPricing" />
        <div id="UseCasePage-body">
          <h1>
            {t("uc-title")} {t(`uc-name-${n}`)}
          </h1>
          <h2>{t("uc-who")}</h2>
          <span dangerouslySetInnerHTML={{ __html: t(`uc-who-${n}`) }}></span>
          <h2>{t("uc-why")}</h2>
          <span dangerouslySetInnerHTML={{ __html: t(`uc-why-${n}`) }}></span>
          <h2>{t("uc-what")}</h2>
          <span dangerouslySetInnerHTML={{ __html: t(`uc-what-${n}`) }}></span>
          <br />

          <a href="https://app.vautours.net" className="btn">
            {t("uc-gs")}
          </a>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //get_smtg: () => dispatch({ type: "GET_smtg" }),
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UseCasePage));
