import React, { Component } from "react";
import {
  Switch,
  Route,
} from "react-router-dom";
import Index from "./pages/Index.js";
import UseCasePage from "./pages/UseCasePage.js";
import AboutUsPage from "./pages/AboutUsPage.js";
import PrivacyPolicy from "./pages/PrivacyPolicy.js";
import DataDeletion from "./pages/DataDeletion.js";
import auth0Client from "./auth0.js";
import i18n from "./my-i18next.js";
import Navigation from "./containers/Navigation.js";
import Footer from "./containers/Footer.js";
import "./App.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onTop: true,
      menuOpen: false,
      isIn: false,
    };
    this.setState({ isIn: auth0Client.isAuthenticated() });
  }
  async componentDidMount() {
    try {
      await auth0Client.silentAuth();
      this.forceUpdate();
      this.setState({ isIn: true });
    } catch (err) {
      console.log(err);
      if (err.error !== "login_required") console.log(err.error);
    }
    this.setState({ isIn: auth0Client.isAuthenticated() });
  }

  signIn = () => {
    auth0Client.signIn();
    this.setState({ isIn: true });
  };

  signOut = () => {
    auth0Client.signOut();
    this.setState({ isIn: false });
  };

  //<Router>
  //<Switch>
  //<Route
  //path="/" //:id"
  //render={props =>}
  ///>
  //</Switch>
  //</Router>

  render() {
    const toggleNav = () => {
      this.setState({ menuOpen: !this.state.menuOpen });
    };
    const changeLanguage = (lng) => {
      let new_lg = lng === "en" ? "fr" : "en";
      i18n.changeLanguage(new_lg);
    };
    return (
      <div className={`page-holder ${this.state.menuOpen ? "menuOpen" : ""}`}>
        <Navigation
          changeLanguage={changeLanguage}
          toggleNav={toggleNav}
          menuOpen={this.state.menuOpen}
          isIn={this.state.isIn}
          signIn={this.signIn}
        />
        <Switch>
          <Route
            exact
            path="/data-deletion/"
            render={(props) => <DataDeletion {...props} />}
          />
          <Route
            exact
            path="/privacy-policy/"
            render={(props) => <PrivacyPolicy {...props} />}
          />
          <Route
            exact
            path="/about-us/"
            render={(props) => <AboutUsPage {...props} />}
          />
          <Route
            exact
            path="/use-case/:id"
            render={(props) => <UseCasePage {...props} />}
          />
          <Route
            exact
            render={(props) => (
              <Index
                isIn={this.state.isIn}
                signIn={this.signIn}
                menuOpen={this.state.menuOpen}
              />
            )}
          />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default App;
