import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={400.299}
      height={444.733}
      viewBox="0 0 400.299 244.733"
      {...props}>
      <g data-name="Group 13">
        <path
          className="smallGoo"
          data-name="Path 9"
          d="M399.158 61.694c16.859-133.1-157-23.133-264.961 70.24s134.83 135.122 174.927 100.997 82.011-90.095 90.034-171.237z"
          fill="#498cca"
          opacity={0.14}
        />
        <path
          data-name="Path 54"
          className="largeGoo"
          d="M120.358 30.655c-34.727 16.592-73.77 49.707-84.797 94.156s14.847 105.733 40.692 83.643c27.881-23.828 78.832-194.392 44.105-177.799z"
          fill="#ff9a9a"
          opacity={0.34}
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
