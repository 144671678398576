import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Header from "./../containers/Header.js";
import HighlightFeat from "./../containers/HighlightFeat.js";
import GetStarted from "./../containers/GetStarted.js";
import UseCases from "./../containers/UseCases.js";
import Features from "./../containers/Features.js";
import Pricing from "./../containers/Pricing.js";
//import API from "./../ApiLayer/index.js";

//setInterval(function () {
  //let svgs = Array.from(document.getElementsByClassName("shape-svg"));
  //svgs.forEach(el => el.classList.toggle("second-form"));
//}, 4000);

class Index extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    console.log(this.props);
  }

  componentWillMount() {}

  render() {
    return (
      <>
        <Header />
        <HighlightFeat />
        <UseCases />
        <GetStarted />
        <Features />
        <Pricing />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //get_smtg: () => dispatch({ type: "GET_smtg" }),
  };
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Index));
