import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={394.143}
      height={331.674}
      viewBox="0 0 394.143 331.674"
      {...props}
    >
      <path

          className="smallGoo"
        data-name="Path 10"
        d="M124.808 1.199C-12.391-16.178-36.238 158.971 52.097 287.623c17.471 25.448 137.813 48.033 224.975 43.458 57.41-3.013 100.39-17.9 89.455-51.686-5.042-14.057-130.641-267.212-241.719-278.196z"
        fill="#1890ff"
        opacity={0.14}
      />
      <path
          className="largeGoo"
        data-name="Path 14"
        d="M263.06 44.176c32.159-7.804 71.478-4.997 90.266 23.908s10.95 93.708-15.109 91.712c-28.118-2.148-107.314-107.815-75.156-115.62z"
        fill="#ff9a9a"
        opacity={0.34}
      />
    </svg>
  )
}

export default SvgComponent
